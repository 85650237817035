import {
  List,
  TextField,
  ExportButton,
  CreateButton,
  TextInput,
  TopToolbar,
  FilterButton,
  FunctionField,
  DatagridConfigurable,
  SelectInput,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const postFilters = [
  <TextInput label="Nome" source="sNome" alwaysOn />,
  <TextInput label="Matricula" source="nMatricula" alwaysOn />,
  <TextInput label="ID (Antigo/legacy)" source="oldId" alwaysOn />,
  <SelectInput
    source="issueReason"
    label="Razão do erro"
    fullWidth
    required
    helperText={false}
    alwaysOn
    choices={[
      { id: "email", name: "Email Duplicado" },
      { id: "cpf", name: "CPF Duplicado" },
      { id: "VIDE", name: "Duplicadas VIDE" },
      { id: "PROV", name: "Conta provisória" },
    ]}
  />,
];
const ListActions = () => (
  <TopToolbar>
    {/* <SelectColumnsButton /> */}
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const OrphanedUsersList = () => {
  return (
    <>
      <PageTopBreadcrumb
        title="Membros Órfãos"
        firstPath="/#/orphaned-users/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List filters={postFilters}>
                <DatagridConfigurable>
                  {/* <TextField source="id" /> */}
                  <TextField source="sNome" label="Nome" />
                  <TextField source="nCPF" label="CPF" />
                  <TextField source="sEmailPart" label="Email" />
                  <TextField source="sTelResid" label="Telefone" />
                  <TextField source="issueReason" label="Erro" />
                  <FunctionField
                    label="Matrícula"
                    render={(record: any) => (
                      <>{record.nMatricula + "-" + record.nDigito}</>
                    )}
                  />
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>O script de importação realiza diversas
            sanitizações e rotinas para otimizar tanto a integridade do banco de
            dados quanto a estrutura dos dados durante a importação. Esta tela
            lista os registros que não puderam ser importados por razões
            específicas.
            <ul>
              <li>
                - Se o cadastro possui a palavra VIDE no nome do sócio, vira
                membro órfão.
              </li>
              <li>
                - Se o cadastro possui asterísco no nome do sócio, vira membro
                órfão.
              </li>

              <li>
                - CPFs ou E-mails já foram adicionados anteriormente, membro
                vira órfão.
              </li>
              <li>
                - A importação acontece de forma regressiva pelo ID legacy do
                sócio, buscando adicionar os registros mais recentes primeiro.
              </li>
            </ul>
          </Alert>
        </CardContent>
      </Card>
    </>
  );
};
