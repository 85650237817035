
import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  useCreateController,
} from "react-admin";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

export const OrphanedUsersCreate = (props: any) => {
  const controller: any = useCreateController({
    resource: "orphaned-users/crud",
  });
  return (
    <CreateContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Usuário Órfão"
          firstPath="/#/orphaned-users/crud"
          secondTitle="Novo"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader
            title="Criar Novo"
            // subheader="September 14, 2016"
          />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12} lg={12}>
                <TextInput
                  source="name"
                  label="Nome"
                  fullWidth
                  helperText={false}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </CreateContextProvider>
  );
};
