import { useState } from "react";
import { Form, useNotify } from "react-admin";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

const CreatePlanoPage = (props: any) => {
  const notify = useNotify();
  const [formValues, setFormValues] = useState({} as any);

  //   useEffect(() => {
  //     getPlans();
  //   }, []);

  const formValidation = (values: any) => {
    const errors: any = [];
    // customer_id
    if (!values.name) {
      errors.name = ["Obrigatório"];
    }
    // plan_identifier
    if (!values.identifier) {
      errors.identifier = ["obrigatório"];
    }
    // value_cents
    if (!values.value_cents) {
      errors.value_cents = ["Obrigatório"];
    }
    // interval
    if (!values.interval) {
      errors.interval = ["Obrigatório"];
    }
    // interval_type
    if (!values.interval_type) {
      errors.interval_type = ["Obrigatório"];
    }
    // if (!values.payable_with || values.payable_with.length === 0) {
    //   errors.payable_with = ["Obrigatório"];
    // }
    return errors;
  };

  const handleSubmit = async () => {
    if (formValidation(formValues).length > 0) {
      notify("Preencha todos os campos obrigatórios", { type: "error" });
      return;
    }

    const formData = new FormData();
    // from formValues
    const { identifier, name, interval, interval_type, payment_methods } =
      formValues;
    // formData.append("payable_with", payment_methods);
    formData.append("name", name);
    formData.append("interval", interval);
    formData.append("interval_type", interval_type);
    formData.append("identifier", identifier);

    try {
      const auth = localStorage.getItem("auth");
      const authJson = auth ? JSON.parse(auth) : auth;
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/iugu/plans`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authJson.access_token,
          },
          body: JSON.stringify(formValues),
        }
      );
      const data = await response.json();
      if (!data.hasOwnProperty("id")) {
        notify(
          "Erro ao criar plano. " +
            (data.errors ? JSON.stringify(data.errors) : ""),
          {
            type: "error",
          }
        );
        return;
      }
      notify("Plano criado com sucesso", { type: "success" });
      // redirect to list after 3 seconds
      setTimeout(() => {
        window.location.href = "/#/iugu-planos";
      }, 3000);
    } catch (error) {
      console.error("IUGU Invoice error", error);
      notify("Erro ao criar plano", { type: "error" });
    }
  };

  return (
    <Form {...props} onSubmit={handleSubmit}>
      <PageTopBreadcrumb
        title="Planos IUGU"
        firstPath="/#/iugu-planos"
        secondTitle="Novo"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardHeader title="Criar" />
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <TextField
                label="Nome do Plano"
                fullWidth
                required
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                label="Identificador do plano"
                fullWidth
                required
                helperText="Identificador único do plano. Ex: 'plano_basico'"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    identifier: e.target.value,
                  })
                }
              />
              <TextField
                label="Valor"
                fullWidth
                required
                value={formValues.value_cents / 100}
                type="number"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    value_cents: parseFloat(e.target.value) * 100,
                  })
                }
              />
              <TextField
                label="Intervalo"
                fullWidth
                required
                type="number"
                helperText="Intervalo de cobrança. Ex: '1'"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    interval: parseInt(e.target.value),
                  })
                }
              />
              <TextField
                label="Tipo de Intervalo"
                fullWidth
                required
                select
                helperText="Ex: 'weeks' or 'months'"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    interval_type: e.target.value,
                  })
                }
              >
                <MenuItem value="weeks">Semanas</MenuItem>
                <MenuItem value="months">Meses</MenuItem>
              </TextField>

              {/* {JSON.stringify(formValues)} */}
              <Button variant="contained" type="submit" color="success">
                Criar Plano
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default CreatePlanoPage;
