
import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  Labeled,
  SelectField,
  NumberField,
  RichTextField,
  useEditController,
} from "react-admin";
import { useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";

export const ProductShow = (props: any) => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "products/crud",
    id,
  });

  return (
    <>
      <ShowContextProvider value={controller}>
        <SimpleShowLayout className="RaShowClass">
          <PageTopBreadcrumb
            title="Item de Cobrança"
            firstPath="/#/products/crud"
            secondTitle="Visualizando"
            thirdTitle={
              controller && controller.record && controller.record.name
            }
            actionType="edit"
          />
          <Card sx={{ padding: 2, marginBottom: 3 }}>
            <CardHeader title="Item de Cobrança" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Stack>
                    <Labeled>
                      <TextField source="id" fullWidth />
                    </Labeled>
                    <Labeled>
                      <TextField source="name" label="Nome" fullWidth />
                    </Labeled>
                    <Labeled>
                      <NumberField source="price" label="Preço" />
                    </Labeled>
                    <Labeled>
                      <RichTextField source="description" label="Descrição" />
                    </Labeled>
                    <Labeled>
                      <SelectField
                        source="status"
                        fullWidth
                        label="Status"
                        choices={[
                          { id: "active", name: "Ativo" },
                          { id: "inactive", name: "Inativo" },
                        ]}
                      />
                    </Labeled>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleShowLayout>
      </ShowContextProvider>
      {/* <ExportButton /> */}
      {/* <DeleteButton /> */}
    </>
  );
};
