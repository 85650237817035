import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import jsonExport from "jsonexport/dist";
import * as XLSX from "xlsx";
import MenuItem from "@mui/material/MenuItem";
import SelectMUI from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { FaDownload } from "react-icons/fa";
import { Button } from "@mui/material";
import { useListContext, downloadCSV, useNotify } from "react-admin";
import ButtonGroup from "@mui/material/ButtonGroup";
import { determineUserReadableRole } from "./UserUtils";

const columnNamesMapping: any = {
  id: "ID",
  createdAt: "Criado em",
  active: "Ativo",
  // registrationNumberDigit: "Dígito Matrícula",
  registrationNumberProv: "Matrícula Provisória",
  userType: "Tipo de Usuário",
  userCategory: "Categoria",
  userCategoryId: "ID Categoria",
  hasPhysicalCard: "Possui Cartão Físico",
  physicalCardNumber: "Número Cartão Físico",
  physicalCardPicture: "Foto Cartão Físico",
  accountStatus: "Status Conta",
  // relative: "Parentesco",
  roleId: "Regra de Usuário",
  subscriptionStatus: "Status Assinatura",
  commercialEmail: "E-mail Comercial",
  commercialPhoneNumber: "Telefone Comercial",
  commercialAddress: "Endereço Comercial",
  commercialAddressNumber: "Número Comercial",
  commercialAddressComplement: "Complemento Comercial",
  commercialAddressNeighborhood: "Bairro Comercial",
  commercialAddressCountry: "País Comercial",
  commercialAddressState: "Estado Comercial",
  commercialAddressRegion: "Região Comercial",
  commercialAddressCity: "Cidade Comercial",
  commercialAddressZipCode: "CEP Comercial",
  userGraduations: "Graduações",
  graduationName1: "Graduação 1 - Nome",
  graduationInstitution1: "Graduação 1 - Inst. de Ensino",
  graduationYear1: "Graduação 1 - Ano",
  graduationName2: "Graduação 2 - Nome",
  graduationInstitution2: "Graduação 2 - Inst. de Ensino",
  graduationYear2: "Graduação 2 - Ano",
  graduationName3: "Graduação 3 - Nome",
  graduationInstitution3: "Graduação 3 - Inst. de Ensino",
  graduationYear3: "Graduação 3 - Ano",
  graduationName4: "Graduação 4 - Nome",
  graduationInstitution4: "Graduação 4 - Inst. de Ensino",
  graduationYear4: "Graduação 4 - Ano",
  graduationName5: "Graduação 5 - Nome",
  graduationInstitution5: "Graduação 5 - Inst. de Ensino",
  graduationYear5: "Graduação 5 - Ano",

  userElections: "Eleições",
  hasCREA: "Possui CREA",
  fullName: "Nome Completo",
  email: "E-mail",
  cpf: "CPF",
  residencePhoneNumber: "Telefone Residencial",
  registrationNumber: "Matrícula",
  birthDate: "Nascimento",
  positionRoles: "Cargos",
  professionalActivity: "Atividade Profissional",
  addressType: "Tipo de Endereço",
  address: "Endereço",
  addressNumber: "Número",
  addressState: "Estado",
  addressZipCode: "CEP",
  addressComplement: "Complemento",
  addressNeighborhood: "Bairro",
  addressCountry: "País",
  addressRegion: "Região",
  addressCity: "Cidade",
  maritalStatus: "Estado Civil",
  dependents: "Dependentes",
  dependent1: "Dependente 1",
  dependent2: "Dependente 2",
  dependent3: "Dependente 3",
  dependent4: "Dependente 4",
  dependent5: "Dependente 5",
  gender: "Gênero",
  phoneNumber: "Celular",
  cancellationDate: "Data de Cancelamento",
  deceasedDate: "Data de Óbito",
  licenseDate: "Data de Licenciado",
  admissionDate: "Data de Admissão",
  aspirantDate: "Data de Aspirante",
  effectiveDate: "Data de Efetivo",
  remissionDate: "Data de Remido",
  contributionDate: "Data de Contribuinte",
  collectiveDate: "Data de Coletivo",
  collectiveRemissionDate: "Data de Coletivo Remido",
  technicalDivisions: "Divisões Técnicas",
  observation: "Observação",
  userEnabled: "Adimplência",
  userLastContribution: "Última Contribuição",
  // educationalInstitution: "Instituição de Ensino",
};

export const ExportColumnSelectionDialog = ({
  onClose,
  onConfirm,
  columns,
  selectedColumns,
  exportFormat,
}: any) => {
  const [selected, setSelected] = useState(selectedColumns);
  const [selectedExportFormat, setSelectedExportFormat] =
    useState(exportFormat); // csv or xlsx

  const handleCheckboxChange = (column: any) => {
    let updatedSelected;
    if (column === "registrationNumber") {
      if (selected.includes(column)) {
        updatedSelected = selected.filter(
          (selectedColumn: any) =>
            selectedColumn !== column &&
            selectedColumn !== "registrationNumberDigit"
        );
      } else {
        updatedSelected = [...selected, column, "registrationNumberDigit"];
      }
    } else {
      updatedSelected = selected.includes(column)
        ? selected.filter((selectedColumn: any) => selectedColumn !== column)
        : [...selected, column];
    }

    setSelected(updatedSelected);
    localStorage.setItem("selectedColumns", JSON.stringify(updatedSelected));
  };
  const handleExportFormatChange = (event: any) => {
    // console.log("event.target.value", event.target.value);
    setSelectedExportFormat(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(selected, selectedExportFormat);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Selecione colunas para exportar</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {columns &&
            columns
              .filter(
                (column: any) =>
                  column !== "password" &&
                  column !== "recoveryToken" &&
                  column !== "avatarId" &&
                  column !== "emailValid" &&
                  column !== "relative" &&
                  column !== "registrationNumberDigit" &&
                  column !== "documents"
              ) // remove password column from export
              .map((column: any) => (
                <Grid item xs={4} key={column}>
                  <Checkbox
                    checked={selected.includes(column)}
                    onChange={() => handleCheckboxChange(column)}
                  />
                  {columnNamesMapping[column] || column}
                </Grid>
              ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small">
          <SelectMUI
            value={selectedExportFormat}
            onChange={handleExportFormatChange}>
            <MenuItem value="csv">CSV</MenuItem>
            <MenuItem value="xlsx">XLSX</MenuItem>
          </SelectMUI>
          <Button variant="contained" onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            Exportar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export const CustomExportButton = (props: any) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data, filterValues } = useListContext();
  const userRole = localStorage.getItem("roles");
  const isMobile = localStorage.getItem("isMobile") === "true";
  // filterValues is an object such as { mailName: "tatiana da silva", hasRegistrationNumber: "false" }
  let columns = data ? Object.keys(data[0] || {}) : [];
  const commsColumns = columns.filter(
    (column: any) =>
      column === "commercialEmail" ||
      column === "fullName" ||
      column === "email" ||
      column === "phoneNumber"
  );
  if (determineUserReadableRole(userRole) === "comms")
    columns = columns.filter((column: any) => commsColumns.includes(column));
  const notify = useNotify();
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleColumnSelectionConfirm = (
    selectedColumns: any,
    exportFormat: "xlsx" | "csv"
  ) => {
    let searchParams = props.location.search; // ?displayedFilters=%7B%7D&filter=%7B%22mailName%22%3A%22tatiana%20da%20silva%22%7D&order=ASC&page=1&perPage=10&sort=id
    if (!searchParams) {
      // if filterValues is not empty
      if (Object.keys(filterValues).length > 0) {
        searchParams = "?filter=" + JSON.stringify(filterValues);
      } else {
        notify("Nenhum filtro selecionado");
        return;
      }
      // console.log("paramsRebuild", searchParams);
    }
    // console.log("props.location.search", searchParams);
    const urlParams = new URLSearchParams(searchParams);
    // console.log("urlParams", searchParams);
    const filters = urlParams.get("filter");
    // console.log("filters", filters);
    const commaColumns = selectedColumns.join(",");
    // fetch data with filters
    // console.log(
    //   "inspection",
    //   `${process.env.REACT_APP_API_PATH}/users/export/${commaColumns}/?filter=${filters}`
    // );
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    fetch(
      `${process.env.REACT_APP_API_PATH}/users/export/${commaColumns}/?filter=${filters}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const dateFields = [
          "createdAt",
          "updatedAt",
          "cancellationDate",
          "deceasedDate",
          "licenseDate",
          "admissionDate",
          "aspirantDate",
          "effectiveDate",
          "remissionDate",
          "contributionDate",
          "collectiveDate",
          "collectiveRemissionDate",
          "birthDate",
        ];
        // convert all data timestamps to human readable format
        data = data.map((item: any) => {
          dateFields.forEach((field: any) => {
            if (item[field]) {
              item[field] = new Date(item[field]).toLocaleDateString("pt-BR");
            }
          });
          return item;
        });

        // remove unwanted columns
        // const filteredData = data.map((item: any) => {
        //   const filteredItem: any = {};
        //   selectedColumns
        //     .filter(
        //       (column: string) =>
        //         column !== "password" && column !== "registrationNumberDigit"
        //     )
        //     .forEach((column: any) => {
        //       filteredItem[column] = item[column];
        //     });
        //   return filteredItem;
        // });
        const filteredData = data;

        // rename columns
        filteredData.forEach((item: any) => {
          for (const key in item) {
            if (columnNamesMapping[key]) {
              item[columnNamesMapping[key]] = item[key];
              delete item[key];
            } else {
              item[key] = item[key];
            }
          }
        });
        // console.log("filteredData", filteredData);
        // export data
        if (exportFormat === "csv") {
          jsonExport(filteredData, (err: any, csv: any) => {
            if (err) {
              console.error(err);
            }
            downloadCSV(csv, "users");
          });
        } else if (exportFormat === "xlsx") {
          // Convert the data to XLS format
          const ws = XLSX.utils.json_to_sheet(filteredData);

          // Create a workbook with a single sheet
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

          // Export the workbook to a file
          XLSX.writeFile(wb, "users.xlsx");
        }
      });
  };

  return (
    <>
      {/* <ExportButton {...props}  /> */}
      {isMobile ? (
        <Button onClick={handleDialogOpen} size="small">
          <FaDownload />
        </Button>
      ) : (
        <Button
          startIcon={<FaDownload />}
          onClick={handleDialogOpen}
          size="small">
          Exportar
        </Button>
      )}

      {isDialogOpen && (
        <ExportColumnSelectionDialog
          onClose={handleDialogClose}
          onConfirm={handleColumnSelectionConfirm}
          columns={columns}
          exportFormat="xlsx"
          // selectedColumns from localStorage
          selectedColumns={JSON.parse(
            localStorage.getItem("selectedColumns") || '["id"]'
          )}
        />
      )}
    </>
  );
};
