import {
  List,
  DatagridConfigurable,
  TextField,
  SingleFieldList,
  ChipField,
  ArrayField,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

export const UserRoleStatusList = () => {
  // const postFilters = [<TextInput label="Nome" source="name" alwaysOn />];
  // const ListActions = () => (
  //   <TopToolbar>
  //     {/* <SelectColumnsButton /> */}
  //     <FilterButton />
  //     <CreateButton />
  //     <ExportButton />
  //   </TopToolbar>
  // );

  return (
    <>
      <PageTopBreadcrumb
        title="Status de Cargos e Funções"
        firstPath="/#/user-role-status/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List>
                <DatagridConfigurable>
                  {/* <TextField source="id" label="ID" /> */}
                  <TextField source="name" label="Nome" />
                  <ArrayField label="Membros" source="members">
                    <SingleFieldList linkType={false}>
                      <ChipField source="name" size="small" />
                    </SingleFieldList>
                  </ArrayField>
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
