import {
  List,
  TextField,
  ExportButton,
  CreateButton,
  TextInput,
  TopToolbar,
  FilterButton,
  BooleanField,
  ReferenceField,
  DatagridConfigurable,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

const postFilters = [
  <TextInput label="Eleição" source="name" alwaysOn />,
  <TextInput label="Ano" source="year" />,
];
const ListActions = () => (
  <TopToolbar>
    {/* <SelectColumnsButton /> */}
    <FilterButton />
    <CreateButton />
    <ExportButton />
    {/* <ImportButton defaultModel={"elections"} /> */}
  </TopToolbar>
);

export const UserElectionList = () => {
  return (
    <>
      <PageTopBreadcrumb
        title="Eleições"
        firstPath="/#/user-elections/crud"
        actionType="create"
        importButton={true}
        defaultModel="elections"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List actions={<ListActions />} filters={postFilters}>
                <DatagridConfigurable>
                  {/* <TextField source="id" /> */}
                  <TextField source="name" label="Eleição" />
                  <BooleanField source="participated" label="Participou" />
                  <TextField source="year" label="Ano" />
                  <ReferenceField
                    source="userId"
                    reference="users/crud"
                    label="Membro"
                  >
                    <TextField source="fullName" size="small" />
                  </ReferenceField>

                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
