import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

type AppLoadingProps = {
  size: number;
  showText: boolean;
};

export const AppLoading = (props: AppLoadingProps) => {
  const { size, showText } = props;

  return (
    <>
      <CircularProgress size={size} /> {showText && "Carregando"}
    </>
  );
};
