const authProvider = {
  login: async ({ identifier, password }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/auth/login`,
        {
          method: "POST",
          body: JSON.stringify({ identifier, password }),
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) throw new Error(response.statusText);
      const auth = await response.json();
      if (!auth || !auth.access_token) {
        throw new Error(
          "Falha na autenticação. Token de acesso não encontrado."
        ); // Changed to Portuguese
      }
      const userResponse = await fetch(
        `${process.env.REACT_APP_API_PATH}/auth/profile`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              auth && auth.access_token ? "Bearer " + auth.access_token : "",
          },
        }
      );
      if (!userResponse.ok) throw new Error(userResponse.statusText);
      const theUser = await userResponse.json();
      const userPermissions = theUser.role.permissions;
      localStorage.setItem("roles", JSON.stringify(userPermissions));
      const redirectMap = {
        ADMIN_MANAGE_ANY_ANYTHING: "/users/crud",
        USER_MANAGE_FINANCE: "/users/crud",
        USER_VIEW_BASIC_CONTENT: "/users/crud",
        USER_VIEW_FULL_CONTENT: "/users/crud",
        USER_MANAGE_OWN_CONTENT: "/users/crud/" + theUser.id + "/show",
      };
      const redirectPermission = userPermissions.find(
        (permission) => redirectMap[permission]
      );
      // console.log("redirectPermission", redirectPermission);
      if (redirectPermission) {
        // console.log(
        //   "Fazendo login, com permissões",
        //   `${window.location.origin}/#${redirectMap[redirectPermission]}`
        // );
        // const redirectTo = `${window.location.origin}/#${redirectMap[redirectPermission]}`;
        // window.location.href = redirectTo; // not working
        // redirect to
        // window.location.replace(redirectTo);
      } else {
        console.warn("Fazendo logout, sem permissões");
        authProvider.logout();
        return;
      }
      localStorage.setItem(
        "auth",
        JSON.stringify({
          access_token: auth ? auth.access_token : "",
          id: theUser ? theUser.id : "",
          fullName: theUser ? `${theUser.fullName}` : "",
        })
      );
      localStorage.setItem("identifier", identifier);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      throw new Error(error.message || "Erro de rede");
    }
  },

  register: async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/auth/register`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || response.statusText);
      }

      setTimeout(() => {
        window.location = "/#/register/success";
      }, 2000);
      return await response.json();
    } catch (error) {
      throw new Error(error.message || "Erro de rede");
    }
  },

  logout: () => {
    ["identifier", "auth", "roles"].forEach((item) =>
      localStorage.removeItem(item)
    );
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem("identifier") ? Promise.resolve() : Promise.reject(),

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      authProvider.logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getIdentity: () => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const id = auth ? auth.id : null;
      const fullName = auth ? auth.fullName : null;
      return Promise.resolve({ id, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => Promise.resolve(""),
};

export default authProvider;
