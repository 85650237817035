import { useFormContext } from "react-hook-form";
import axios from "axios";
import { TextInput } from "react-admin";
export const ZIPComponent = () => {
  const { reset, setValue, getValues } = useFormContext();

  const fetchCep = async (cep: any) => {
    try {
      const response = await axios.get(`//viacep.com.br/ws/${cep}/json`);
      if (response.status !== 200) {
        throw new Error("Erro ao buscar CEP");
      }
      const { data } = response;
      // console.log("valuesTest", getValues());
      setValue("addressZipCode", cep);
      setValue("addressState", data.uf);
      setValue("addressCity", data.localidade);
      setValue("addressNeighborhood", data.bairro);
      setValue("address", data.logradouro);
      return { ...data };
    } catch (error) {
      console.error("Erro ao buscar CEP: ", error);
      return null;
    }
  };
  // format zip code to output 00000-000
  const formatZipCode = (v: string) => {
    if (!v) return v;
    if (v.length === 8) {
    }
    const cleanedZipCode = v.replace(/\D/g, "");
    const match = cleanedZipCode.match(/^(\d{5})(\d{3})$/);
    if (match) {
      return `${match[1]}-${match[2]}`;
    }
    return v;
  };

  return (
    <TextInput
      source="addressZipCode"
      label="CEP"
      fullWidth
      onChange={(e) => {
        if (e.target.value.length >= 7) fetchCep(e.target.value);
      }}
      helperText={"Auto-completa endereço"}
      format={(v: any) => formatZipCode(v)}
    />
  );
};
