import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  Labeled,
  useEditController,
} from "react-admin";
import { useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";

export const FeedbacksShow = () => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "feedbacks/crud",
    id,
  });
  return (
    <>
      <ShowContextProvider value={controller}>
        <SimpleShowLayout className="RaShowClass">
          <PageTopBreadcrumb
            title="Contatos"
            firstPath="/#/feedbacks/crud"
            secondTitle="Visualizando"
            thirdTitle={
              controller && controller.record && controller.record.email
            }
            actionType="edit"
          />
          <Card sx={{ padding: 2, marginBottom: 3 }}>
            <CardHeader title="Contato" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Stack>
                    <Labeled>
                      <TextField source="id" label="ID" />
                    </Labeled>
                    <Labeled>
                      <TextField source="email" label="E-mail" />
                    </Labeled>
                    <Labeled>
                      <TextField source="name" label="Nome" />
                    </Labeled>
                    <Labeled>
                      <TextField
                        source="message"
                        label="Message"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Labeled>
                    <Labeled>
                      <TextField source="type" label="Type" fullWidth />
                    </Labeled>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleShowLayout>
      </ShowContextProvider>
    </>
  );
};
