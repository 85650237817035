export function ApiPage(props: any) {
  return (
    <iframe
      src={process.env.REACT_APP_API_PATH + "/api"}
      title="API"
      height="100%"
      style={{
        overflow: "hidden",
        height: "100%",
        width: "100%",
      }}
    />
  );
}
