import React, { useEffect, useState } from "react";
import { useLogin, useNotify } from "react-admin";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Button,
  Link,
} from "@mui/material";
import { AppError } from "../../AppError";
import CryptoJS from "crypto-js";

const WpLogin = () => {
  const [userToken, setUserToken] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userIdentifier, setUserIdentifier] = useState("");
  const location = useLocation();
  const [error, setError] = useState(null);
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    setUserToken(token as string);
    const identifier = params.get("identifier");
    setUserIdentifier(identifier as string);
    const encodedPassword = params.get("password") as string;
    setUserPassword(encodedPassword);
    // URL-safe Base64 decode the encrypted password
    const base64EncodedPassword = encodedPassword
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const encryptedPassword = atob(base64EncodedPassword);
    // Decrypt the password
    const bytes = CryptoJS.AES.decrypt(encryptedPassword, "secret_key");
    const password = bytes.toString(CryptoJS.enc.Utf8);
    // console.log("test pass", password);
    if (token) {
      const fetchUserProfile = async () => {
        try {
          const userProfileResponse = await fetch(
            `${process.env.REACT_APP_API_PATH}/auth/profile`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!userProfileResponse.ok) {
            throw new Error(userProfileResponse.statusText);
          }

          const userProfile = await userProfileResponse.json();
          const userPermissions = userProfile.role.permissions;
          const redirectMap: any = {
            ADMIN_MANAGE_ANY_ANYTHING: "/users/crud",
            USER_MANAGE_OWN_CONTENT: `/users/crud/${userProfile.id}/show`,
          };
          const redirectPath = userPermissions.find(
            (permission: any) => redirectMap[permission]
          );

          // Perform the login
          login(
            { identifier, password },
            redirectMap[redirectPath] || "/users/crud"
          );
        } catch (error: any) {
          setError(error.message);
          notify(error.message);
        }
      };

      fetchUserProfile();
    } else {
      notify("No token provided");
    }
  }, [location]);

  //   return <div>{error ? <p>{error}</p> : <p>Fazendo o login...</p>}</div>;
  return (
    <Paper elevation={2}>
      <Card>
        <CardHeader
          title="Acesso Clube de Engenharia"
          subheader="Realizando seu login..."
        />
        <CardContent>
          {!userToken || !userPassword || !userIdentifier || error ? (
            <AppError
              message={error || "Houve um problema com sua solicitação"}
              title="Erro"
              severity="error"
            />
          ) : (
            <Typography variant="body1">Fazendo o login...</Typography>
          )}
          {/* {JSON.stringify({ userToken, userPassword, userIdentifier })} */}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default WpLogin;
