import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  useCreateController,
} from "react-admin";
import { JsonInput } from "react-admin-json-view";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";

export const UserRolesCreate = (props: any) => {
  const controller: any = useCreateController({
    resource: "user-roles/crud",
  });
  return (
    <CreateContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Regras de Usuários"
          firstPath="/#/user-roles/crud"
          secondTitle="Novo"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Criar" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12} lg={12}>
                <TextInput source="name" fullWidth label="Nome" />
                <JsonInput
                  source="permissions"
                  label="Permissões"
                  jsonString={false} // Set to true if the value is a string, default: false
                  reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </CreateContextProvider>
  );
};
