export const statusLabels: any = {
  canceled: "Cancelada",
  pending: "Pendente",
  paid: "Paga",
  partially_paid: "Parcialmente Paga",
  refunded: "Estornada",
  expired: "Expirada",
  in_protest: "Em Protesto",
  chargeback: "Chargeback",
  authorized: "Autorizada",
  in_analysis: "Em Análise",
  draft: "Rascunho",
  externally_paid: "Pago Externamente",
};
