import { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormContext } from "react-hook-form";
import { useNotify } from "react-admin";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FaTrash, FaUpload } from "react-icons/fa";
export const FileUploadComponent = (props: any) => {
  const notify = useNotify();

  const { getValues, setValue, getFieldState } = useFormContext();
  //   const { save } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState<any>({});
  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadMessage("Favor selecionar um arquivo para enviar.");
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/users/upload/file`,
        formData,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            // Authorization: `Bearer ${authJson.access_token}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // console.log("img uploaded", response.data);
        setUploadMessage("Arquivo enviado com sucesso.");
        // setUploadedFile(response.data);
        setValue("physicalCardPicture", response.data);
        notify("Enviando arquivo para o servidor, aguarde...", {
          type: "warning",
        });

        if (props.save) {
          setTimeout(() => {
            props.save(getValues());
          }, 3000);
        }
      } else {
        setUploadMessage("Envio do arquivo falhou, consulte o suporte. #1");
      }
    } catch (error: any) {
      console.error("Error uploading file:", error.message);
      setUploadMessage("Envio do arquivo falhou, consulte o suporte. #2");
    } finally {
      setIsUploading(false);
    }
  };
  /**
   * Evaluates if the sendFile field should be shown.
   * It should show if:
   * - we can access props.controller.record.hasPhysicalCard but hasPhysicalCard.path is empty (Eg.: {}) (editing existing record)
   * - there's no props.controller or id (creating new record)
   * @param props any
   * @returns boolean
   */
  function showSendFileField(props: any) {
    const physicalCardPicture = props?.controller?.record?.physicalCardPicture;
    const physicalCardPicturePath = physicalCardPicture?.path;
    const hasPhysicalCardPathEmpty =
      physicalCardPicturePath === undefined ||
      physicalCardPicturePath === null ||
      physicalCardPicturePath === "";
    const isCreatingNewRecord = !props.controller || !props.id;
    // console.log(
    //   "showSendFileField",
    //   (physicalCardPicture && hasPhysicalCardPathEmpty) || isCreatingNewRecord
    // );
    return (
      (physicalCardPicture && hasPhysicalCardPathEmpty) ||
      isCreatingNewRecord ||
      !physicalCardPicture
    );
  }
  return (
    <div>
      {showSendFileField(props) && (
        <>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <Button
            variant="contained"
            color="primary"
            startIcon={<FaUpload />}
            onClick={handleUpload}
            disabled={isUploading}>
            Enviar arquivo
          </Button>
        </>
      )}

      {props.controller &&
        props.id &&
        getValues() &&
        getValues().physicalCardPicture &&
        getValues().hasOwnProperty("physicalCardPicture") &&
        typeof getValues().physicalCardPicture !== "undefined" &&
        getValues().physicalCardPicture.hasOwnProperty("path") && (
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="caption">Foto Atual: </Typography>
              <img
                src={
                  `${process.env.REACT_APP_UPLOAD_PATH}/` +
                  getValues().physicalCardPicture.path
                }
                alt="Foto"
                width={50}
              />
            </Grid>
            <Grid item xs={5}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<FaTrash />}
                color="primary"
                onClick={async () => {
                  // setValue("physicalCardPicture", null);
                  const auth = localStorage.getItem("auth");
                  const authJson = auth ? JSON.parse(auth) : auth;
                  const emptyCardPicture = await axios.put(
                    `${process.env.REACT_APP_API_PATH}/users/crud/${props.id}`,
                    {
                      physicalCardPicture: {},
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${authJson.access_token}`,
                      },
                    }
                  );
                  const deleteFile = await axios.delete(
                    `${process.env.REACT_APP_API_PATH}/users/upload/file/${
                      getValues().physicalCardPicture.name
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${authJson.access_token}`,
                      },
                    }
                  );
                  // console.log("deleteFile", deleteFile);
                  // console.log("emptyCardPicture", emptyCardPicture);
                  window.location.reload();
                  // setValue("physicalCardPicture", null);
                }}
                disabled={isUploading}>
                Remover Foto
              </Button>
            </Grid>
          </Grid>
        )}
      {isUploading && <CircularProgress />}
      {uploadMessage && <p>{uploadMessage}</p>}
    </div>
  );
};

export default FileUploadComponent;
