import {
  TextInput,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
} from "react-admin";

export const postFilters = [
  <TextInput label="Nome / E-mail / CPF" source="mailName" alwaysOn />,
  <SelectInput
    source="hasRegistrationNumber"
    label="Possui Matrícula"
    // defaultValue={"true"}
    choices={[
      { id: "true", name: "Sim" },
      { id: "false", name: "Não" },
    ]}
    alwaysOn={"true"}
  />,
  <NumberInput label="Matrícula (sem dígito)" source="registrationNumber" />,
  <TextInput label="Endereço (Cidade, rua, cep...)" source="addressQuery" />,

  // <DateInput source="effectiveDate" label="Data Efetivo de" />,
  <ReferenceArrayInput
    source="technicalDivisions"
    label="Divisões Técnicas"
    reference="technical-divisions/crud">
    <SelectArrayInput label="Divisões Técnicas" />
  </ReferenceArrayInput>,
  <TextInput label="Formação" source="userGraduationQuery" />,
  <TextInput label="Atividade Profissional" source="professionalActivity" />,

  <TextInput
    label="Instituição de Ensino"
    source="userEducationalInstituteQuery"
  />,
  <ReferenceInput
    label="Categoria"
    source="userCategoryId"
    reference="user-categories/crud">
    <SelectArrayInput label="Categoria de Membro" optionText="name" />
  </ReferenceInput>,
  <TextInput label="Nº CREA" source="CREA" />,
  <SelectInput
    source="hasCREA"
    label="Possui CREA"
    choices={[
      { id: "true", name: "Sim" },
      { id: "false", name: "Não" },
    ]}
  />,
  <SelectArrayInput
    source="accountStatus"
    label="Situação"
    choices={[
      { id: "ACTIVE", name: "Ativo" },
      { id: "CANCELED", name: "Cancelado" },
      { id: "NO_CONTACT", name: "Sem contato" },
      { id: "LICENSED", name: "Licenciado" },
      { id: "DECEASED", name: "Falecido" },
      { id: "UNKNOWN", name: "Desconhecido" },
      { id: "PENDING", name: "Pendente" },
    ]}
  />,
  <SelectInput
    source="hasPhysicalCard"
    label="Possui Carteirinha"
    choices={[
      { id: "true", name: "Sim" },
      { id: "false", name: "Não" },
    ]}
  />,
  <SelectInput
    source="gender"
    label="Sexo"
    choices={[
      { id: "M", name: "Masculino" },
      { id: "F", name: "Feminino" },
    ]}
  />,
  <SelectInput
    source="maritalStatus"
    label="Estado Civil"
    choices={[
      { id: "SOLTEIRO", name: "Solteiro(a)" },
      { id: "CASADO", name: "Casado(a)" },
      { id: "DESQUITADO", name: "Desquitado(a)" },
      { id: "DIVORCIADO", name: "Divorciado(a)" },
      { id: "VIUVO", name: "Viúvo(a)" },
      { id: "UNIAO_ESTAVEL", name: "União Estável" },
    ]}
  />,
  <ReferenceInput
    label="Regra de Usuário"
    source="roleId"
    reference="user-roles/crud">
    <SelectInput label="Regra de Usuário" optionText="name" />
  </ReferenceInput>,
  <SelectInput
    source="subscriptionStatus"
    label="Status Assinatura IUGU"
    choices={[
      { id: "active", name: "Adimplente" },
      { id: "due", name: "Inadimplente" },
      { id: "suspended", name: "Suspensa" },
    ]}
  />,
  <SelectInput
    source="userEnabled"
    label="Adimplência (Regra Customizada)"
    title="A lista de membros adimplentes/inadimplentes atualizam em
    background a cada 3 minutos e armazenadas em cache, alterações
    que afetem adimplência podem levar até 3 min para surtir
    efeito na visualização desta lista de Sócios. (Este cache não
    afeta exportação de membros, mediante a exportação os dados
    são solicitados em tempo real.)"
    choices={[
      { id: "true", name: "Adimplente" },
      { id: "false", name: "Inadimplente" },
    ]}
  />,
  <TextInput label="Última Contribuição" source="userLastContribution" />,
  <SelectInput
    label="Aniversariantes do Mês"
    source="birthMonth"
    choices={[
      { id: "1", name: "Janeiro" },
      { id: "2", name: "Fevereiro" },
      { id: "3", name: "Março" },
      { id: "4", name: "Abril" },
      { id: "5", name: "Maio" },
      { id: "6", name: "Junho" },
      { id: "7", name: "Julho" },
      { id: "8", name: "Agosto" },
      { id: "9", name: "Setembro" },
      { id: "10", name: "Outubro" },
      { id: "11", name: "Novembro" },
      { id: "12", name: "Dezembro" },
    ]}
  />,
];
