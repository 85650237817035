import { JsonInput } from "react-admin-json-view";
import {
  EditContextProvider,
  SimpleForm,
  TextInput,
  useEditController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";

export const UserRolesEdit = (props: any) => {
  const { id } = useParams();
  const controller = useEditController({
    resource: "user-roles/crud",
    mutationMode: "pessimistic",
    id,
  });
  return (
    <EditContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Regras de Usuários"
          firstPath="/#/user-roles/crud"
          secondTitle="Editando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="show"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Regra de Usuário" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <TextInput source="id" fullWidth />
                <TextInput source="name" label="Nome" fullWidth />

                {/* <AutocompleteInput source="permissions" /> */}
                <JsonInput
                  source="permissions"
                  label="Permissões"
                  jsonString={false} // Set to true if the value is a string, default: false
                  reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,

                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </EditContextProvider>
  );
};
