import { useEffect, useState } from "react";

import moment from "moment";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Grid,
  Card,
  TextField,
  CardContent,
  Typography,
  ButtonGroup,
} from "@mui/material";
import { IuguPagination } from "./IuguPagination";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { statusLabels } from "./iugu-utils";
import { truncateString } from "../../../ra-components/users/UserUtils";

export const IuguInvoicesPage = () => {
  const queryUrl = window.location.href;
  // extract uid from url
  let iugu_id = queryUrl.split("/").pop();
  iugu_id = iugu_id ? iugu_id.split("=").pop() : "";

  if (iugu_id === "iugu-invoices") {
    iugu_id = "";
  }

  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState(iugu_id || "");

  const cancelInvoice = async (id: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/invoices/${id}/cancel`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    console.log({ data });
    window.location.reload();
  };
  const getInvoices = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;

    // https://api.iugu.com/v1/invoices?start=${(page - 1) * 5}&limit=5
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/invoices?s=${start}&l=${perPage}&q=${query}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    console.log({ getInvoices: data });
    setInvoices(data.items);
    return data;
  };
  useEffect(() => {
    getInvoices();
  }, [page, query]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTopBreadcrumb
          title="Faturas IUGU"
          firstPath="/#/iugu-invoices"
          actionType="create"
        />
        <Card sx={{ padding: 2, marginBottom: 3 }}>
          {/* <CardHeader title={"Informações Pessoais"} /> */}

          <CardContent>
            <TextField
              type="text"
              placeholder="Pesquisar"
              helperText="E-mail, nome, anotações e ID da fatura"
              defaultValue={iugu_id}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            {/* {JSON.stringify(invoices)} */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Dados da Fatura</TableCell>
                    <TableCell>Itens</TableCell>
                    {/* <TableCell>Vencimento</TableCell> */}
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices.map((invoice: any) => (
                    <TableRow key={invoice.id}>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          title={invoice.id}
                          onClick={() => {
                            // window.location.href =
                            //   "/#/invoices/crud/" + invoice.id + "/show";
                          }}
                        >
                          {truncateString(invoice.id, 13)}
                        </Button>
                        <Typography variant="caption">
                          <br />
                          <b>Cliente:</b> {invoice.customer_name || "Sem nome"}{" "}
                          ({invoice.customer_id || "Sem ID"})
                          <br />
                          <b>E-mail:</b> {invoice.email || "Sem email"}
                          <br />
                          <b>Status:</b>{" "}
                          {statusLabels[invoice.status] || invoice.status}
                          <br />
                          {invoice.paid_at && (
                            <>
                              <b>Pago: </b>
                              {invoice.paid}
                              <br />
                              <b>Pago em: </b>{" "}
                              {moment(invoice.paid_at).format("DD-MM-YYYY")}
                              <br />
                            </>
                          )}
                          <b>Vencimento:</b>{" "}
                          {moment(invoice.due_date).format("DD-MM-YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Item</TableCell>
                              <TableCell>Qntd.</TableCell>
                              <TableCell>Valor</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoice.items.map((item: any) => (
                              <TableRow key={item.id}>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>
                                  R$ {(item.price_cents / 100).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell>
                                <Typography variant="caption">
                                  <b>Total</b>
                                </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                <Typography variant="caption">
                                  <b>
                                    R${" "}
                                    {(parseInt(invoice.total_cents) / 100)
                                      .toFixed(2)
                                      .replace(".", ",")}
                                  </b>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>

                      <TableCell>
                        <ButtonGroup fullWidth size="small">
                          <Button
                            variant="contained"
                            color="info"
                            size="small"
                            title="Visualizar fatura"
                            onClick={() => {
                              window.open(`${invoice.secure_url}`);
                            }}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            disabled={
                              invoice.status === "canceled" ||
                              invoice.status === "paid"
                            }
                            title="Cancelar fatura"
                            onClick={() => {
                              cancelInvoice(invoice.id);
                            }}
                          >
                            <CancelIcon />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <IuguPagination setPage={setPage} page={page} list={invoices} />
      </Grid>
    </Grid>
  );
};
