import { List, DatagridConfigurable, TextField, TextInput } from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
export const MailTemplateList = () => {
  const postFilters = [<TextInput label="Nome" source="name" alwaysOn />];
  return (
    <>
      <PageTopBreadcrumb
        title="Template de E-mail"
        firstPath="/#/mail-templates/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List filters={postFilters}>
                <DatagridConfigurable>
                  {/* <TextField source="id" label="ID" /> */}
                  <TextField source="name" label="Nome" />
                  <TextField source="trigger" label="Gatilho" />
                  <TextField source="subject" label="Assunto" />
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
          <Typography variant="body2" gutterBottom>
            <b>Gatilhos possíveis:</b> <br />
            <ul>
              <li>NEW_ACCOUNT_MEMBER</li>
              <li>NEW_ACCOUNT_ADMIN</li>
              <li>NEW_CONTACT_ADMIN</li>
              <li>ACCOUNT_RECOVERY</li>
              <li>NEW_CONTACT_MESSAGE</li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};
