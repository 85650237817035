import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select as SelectMUI,
  MenuItem,
  Divider,
  Alert,
} from "@mui/material";
import { useNotify } from "react-admin";

const ImportButton = (props: any) => {
  const { defaultModel } = props;
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [model, setModel] = useState(defaultModel);
  const notify = useNotify();
  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImport = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        console.log("test", file, formData);
        await fetch(`${process.env.REACT_APP_API_PATH}/uploads/csv/${model}`, {
          method: "POST",
          body: formData,
        }).then(async (response) => {
          console.log("response", response);
          const data: any = response;
          if (response.status === 200 || response.status === 201) {
            notify("Importação iniciada com sucesso. Aguarde.", {
              type: "success",
            });
          } else {
            const message = data && data.message ? data.message : "";
            notify(
              `Erro ao importar arquivo. (${message}) - [${response.status}]`,
              {
                type: "error",
              }
            );
            return;
          }
        });
        // Handle success, e.g., show a notification
      } catch (error) {
        console.log("error", error);
        // Handle error, e.g., show an error notification
        notify("Erro ao importar arquivo.", {
          type: "error",
        });
      }

      // Close the dialog
      handleClose();
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        sx={{ marginRight: 0.5 }}
        onClick={handleOpen}
        startIcon={<CloudUploadIcon />}
      >
        Importar
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Importar de CSV</DialogTitle>
        <DialogContent>
          <input type="file" onChange={handleFileChange} />
          <Divider></Divider>
          <hr />
          <SelectMUI
            onChange={(e: any) => setModel(e.target.value)}
            label="Modelo"
            fullWidth
            value={model}
          >
            <MenuItem value="--">Selecione um modelo</MenuItem>
            <MenuItem value="users">Membros</MenuItem>
            <MenuItem value="elections">Eleições</MenuItem>
            <MenuItem value="position-roles">Cargos</MenuItem>
          </SelectMUI>
          {model && model === "elections" && (
            <>
              <Alert severity="warning">
                Para importar "{model}" é necessário que o arquivo CSV contenha
                as colunas abaixo na primeira linha:
                <br />
                <br />
                <b>nome,ano,participou,cpf</b>
              </Alert>
            </>
          )}
          {model && model === "position-roles" && (
            <>
              <Alert severity="warning">
                Para importar "{model}" é necessário que o arquivo CSV contenha
                as colunas abaixo na primeira linha:
                <br />
                <br />
                <b>matricula,nome,cargo,anuenio</b>
              </Alert>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" size="small">
            Cancelar
          </Button>

          <Button onClick={handleImport} color="primary" size="small">
            Importar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportButton;
