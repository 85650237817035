import { validarCPF } from "./CPFUtils";

export const validateUserResource = (values: any) => {
  // console.log("validating", values);
  const errors: any = {};
  if (!values.fullName) {
    errors.fullName = "Nome é requerido";
  }
  if (!values.email) {
    errors.email = "E-mail é requerido";
  }
  const maxSelections = 2; // Change this to your desired maximum limit
  if (values && values.technicalDivisions.length > maxSelections) {
    const message = `Só é possível selecionar até ${maxSelections} DTEs.`;
    errors.technicalDivisions = message;
  }
  // if cpf is valid
  if (values.cpf && !validarCPF(values.cpf)) {
    errors.cpf = "CPF vazio ou inválido";
  }
  return errors;
};
