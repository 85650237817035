import {
  EditContextProvider,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  useEditController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";

export const FeedbacksEdit = (props: any) => {
  const { id } = useParams();
  const controller = useEditController({
    resource: "feedbacks/crud",
    id,
    mutationMode: "pessimistic",
  });
  return (
    <>
      <EditContextProvider value={controller}>
        <SimpleForm>
          <PageTopBreadcrumb
            title="Contatos"
            firstPath="/#/feedbacks/crud"
            secondTitle="Editando"
            thirdTitle={
              controller && controller.record && controller.record.email
            }
            actionType="show"
          />
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Contato" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <TextInput source="id" fullWidth label="ID" />
                  <TextInput source="email" fullWidth label="E-mail" />
                  <TextInput source="message" fullWidth label="Mensagem" />
                  <AutocompleteInput
                    source="type"
                    label="Selecione um tipo de contato"
                    choices={[
                      { id: "other", name: "other" },
                      { id: "bug", name: "bug" },
                      { id: "feature", name: "feature" },
                      { id: "contact", name: "contact" },
                    ]}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleForm>
      </EditContextProvider>
    </>
  );
};
