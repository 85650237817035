import {
  EditContextProvider,
  SimpleForm,
  TextInput,
  useEditController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";
import { RichTextInput } from "ra-input-rich-text";

export const MailTemplateEdit = (props: any) => {
  const { id } = useParams();
  const controller = useEditController({
    resource: "mail-templates/crud",
    mutationMode: "pessimistic",
    id,
  });

  return (
    <EditContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Template de E-mail"
          firstPath="/#/mail-templates/crud"
          secondTitle="Editando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="show"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Template de E-mail" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <TextInput source="id" fullWidth helperText={false} disabled />
                <TextInput
                  source="name"
                  fullWidth
                  helperText={false}
                  label="Nome"
                />
                <TextInput
                  source="trigger"
                  label="Gatilho"
                  fullWidth
                  helperText={false}
                />
                <TextInput
                  source="subject"
                  label="Assunto"
                  fullWidth
                  helperText={false}
                />
                <div className="richTextBody">
                  <RichTextInput
                    source="body"
                    label="Corpo"
                    fullWidth
                    helperText={false}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </EditContextProvider>
  );
};
