import { FunctionField } from "react-admin";
import Typography from "@mui/material/Typography";
import moment from "moment";

type UserListDateColumnProps = {
  record: any;
};

export const UserListDateColumn = (props: UserListDateColumnProps) => {
  const { record } = props;
  return (
    <div className="flex flex-col flex-1">
      {record.remissionDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "remissionDate"
                  ? "bold"
                  : "normal"
              }>
              {record.remissionDate &&
                "Remido em: " +
                  moment(record.remissionDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.collectiveRemissionDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "collectiveRemissionDate"
                  ? "bold"
                  : "normal"
              }>
              {record.collectiveRemissionDate &&
                "Coletivo Remido em: " +
                  moment(record.collectiveRemissionDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.collectiveDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "collectiveDate"
                  ? "bold"
                  : "normal"
              }>
              {record.collectiveDate &&
                "Coletivo em: " +
                  moment(record.collectiveDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.contributionDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "contributionDate"
                  ? "bold"
                  : "normal"
              }>
              {record.contributionDate &&
                "Contribuinte em: " +
                  moment(record.contributionDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.aspirantDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "aspirantDate"
                  ? "bold"
                  : "normal"
              }>
              {record.aspirantDate &&
                "Aspirante em: " +
                  moment(record.aspirantDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}

      {record.effectiveDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "effectiveDate"
                  ? "bold"
                  : "normal"
              }>
              {record.effectiveDate &&
                "Efetivo em: " +
                  moment(record.effectiveDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}

      {record.admissionDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "admissionDate"
                  ? "bold"
                  : "normal"
              }>
              {record.admissionDate &&
                "Admissão em: " +
                  moment(record.admissionDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.licenseDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "licenseDate"
                  ? "bold"
                  : "normal"
              }>
              {record.licenseDate &&
                "Licenciado em: " +
                  moment(record.licenseDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.cancellationDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "cancellationDate"
                  ? "bold"
                  : "normal"
              }>
              {record.cancellationDate &&
                "Cancelado em: " +
                  moment(record.cancellationDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.birthDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "birthDate"
                  ? "bold"
                  : "normal"
              }>
              {record.birthDate &&
                "Nascimento em: " +
                  moment(record.birthDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.deceasedDate && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "deceasedDate"
                  ? "bold"
                  : "normal"
              }>
              {record.deceasedDate &&
                "Falecimento em: " +
                  moment(record.deceasedDate).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.createdAt && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "createdAt"
                  ? "bold"
                  : "normal"
              }>
              {record.createdAt &&
                "Criado em: " + moment(record.createdAt).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
      {record.updatedAt && (
        <>
          <div className="text-xs text-gray-600">
            <Typography
              fontSize={11}
              fontWeight={
                localStorage.getItem("dateField") === "updatedAt"
                  ? "bold"
                  : "normal"
              }>
              {record.updatedAt &&
                "Última atualização em: " +
                  moment(record.updatedAt).format("DD/MM/YYYY")}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
