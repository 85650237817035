import { JsonField } from "react-admin-json-view";
import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  Labeled,
  useEditController,
} from "react-admin";
import { useParams } from "react-router-dom";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

export const SystemSettingShow = () => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "system-settings/crud",
    id,
  });
  return (
    <ShowContextProvider value={controller}>
      <SimpleShowLayout className="RaShowClass">
        <PageTopBreadcrumb
          title="Configuração de sistema"
          firstPath="/#/system-settings/crud"
          secondTitle="Visualizando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="edit"
        />
        <Card sx={{ padding: 2, marginBottom: 3 }}>
          <CardHeader title="Configuração de sistema" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Stack>
                  <Labeled>
                    <TextField source="id" />
                  </Labeled>
                  <Labeled>
                    <TextField source="name" />
                  </Labeled>
                  <Labeled>
                    <JsonField source="value" />
                  </Labeled>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleShowLayout>
    </ShowContextProvider>
  );
};
