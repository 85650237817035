// import { useState } from "react";
import {
  AutocompleteArrayInput,
  SimpleForm,
  CreateContextProvider,
  ReferenceArrayInput,
  ArrayInput,
  DateInput,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  useCreateController,
  FormGroupsProvider,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { ZIPComponent } from "./ZIPComponent";
import { formatCPF } from "./CPFUtils";
import { validateUserResource } from "./UserValidation";
import FileUploadComponent from "./FileUploadComponent";
import { JsonInput } from "react-admin-json-view";
import { CreateGraduation } from "./CreateGraduationModal";
import { CreateEducationalInstitution } from "./CreateEducationalInstitutionModal";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
// import Button from "@mui/material/Button";
import { AutoPopulateUser } from "./AutoPopulateUser";
import { determineUserReadableRole } from "./UserUtils";
export const UserCreate = (props: any) => {
  const urlParams = useParams();
  const orphanedId = urlParams["*"];
  // console.log("useparams", orphanedId);
  const controller: any = useCreateController({ resource: "users/crud" });
  const userRole = localStorage.getItem("roles");
  // const [formRecords, setFormRecords] = useState<any>({});

  const filterToQuery = (searchText: any) => ({
    name: `${searchText}`,
    // reviewed: "true",
  });

  return (
    <CreateContextProvider value={controller}>
      <SimpleForm validate={validateUserResource}>
        <PageTopBreadcrumb
          title="Sócios"
          firstPath="users/crud"
          secondTitle="Novo"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader
            title="Informações Pessoais"
            // subheader="September 14, 2016"
          />
          <CardContent>
            <AutoPopulateUser orphanedId={orphanedId} />

            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <FormGroupsProvider>
                  <TextInput
                    required
                    label="Nome"
                    source="fullName"
                    fullWidth
                    // {...register("fullName")}
                    helperText={false}
                  />
                </FormGroupsProvider>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  required
                  source="email"
                  label="Email Particular"
                  type="email"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              {/* <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="login"
                  label="login"
                  fullWidth
                  helperText={false}
                />
              </Grid> */}
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  source="password"
                  label="Senha"
                  fullWidth
                  type="password"
                  helperText={false}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  required
                  source="cpf"
                  label="CPF"
                  format={(v: any) => formatCPF(v)}
                  // parse={(v: any) => {
                  //   validarCPF(v);
                  // }}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextInput
                  source="CREA"
                  label="CREA"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="professionalActivity"
                  label="Atividade Profissional Principal"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <DateInput
                  source="birthDate"
                  label="Data de Nascimento"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <SelectInput
                  source="gender"
                  label="Sexo"
                  fullWidth
                  helperText={false}
                  choices={[
                    { id: "M", name: "Masculino" },
                    { id: "F", name: "Feminino" },
                  ]}
                  // onChange={(e: any) => {
                  //   console.log(
                  //     "formChange",
                  //     e.target.name,
                  //     e.target.value,
                  //     formRecords
                  //   );
                  //   setFormRecords({
                  //     ...formRecords,
                  //     [e.target.name]: e.target.value,
                  //   });
                  // }}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <SelectInput
                  source="maritalStatus"
                  label="Estado Civil"
                  fullWidth
                  helperText={false}
                  choices={[
                    { id: "SOLTEIRO", name: "Solteiro(a)" },
                    { id: "CASADO", name: "Casado(a)" },
                    { id: "DESQUITADO", name: "Desquitado(a)" },
                    { id: "DIVORCIADO", name: "Divorciado(a)" },
                    { id: "VIUVO", name: "Viúvo(a)" },
                    { id: "UNIAO_ESTAVEL", name: "União Estável" },
                  ]}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="phoneNumber"
                  label="Celular"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="residencePhoneNumber"
                  label="Telefone Residencial"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressState"
                  label="Estado"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <TextInput
                  source="address"
                  label="Endereço"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressCity"
                  label="Cidade"
                  fullWidth
                  helperText={false}
                />
              </Grid>

              <Grid item lg={2} md={6} xs={12}>
                <ZIPComponent />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressNeighborhood"
                  label="Bairro"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="addressRegion"
                  label="Região"
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12}>
                <ArrayInput source="dependents" label="Dependentes">
                  <SimpleFormIterator
                    inline
                    fullWidth
                    getItemLabel={(index) => `#${index + 1}`}>
                    <TextInput source="name" label="Nome" />
                    <TextInput source="birthDate" label="Nascimento" />
                    <TextInput source="kinship" label="Parentesco" />
                    {/* <TextInputMUI
                      name={`dependents[${index}].name`}
                      label={`Nome do Dependente #${index + 1}`}
                      fullWidth
                      helperText={false}
                    /> */}
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={0.5}>
          <Grid lg={8} xs={12} item>
            <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
              <CardHeader title="Informações Comerciais" />
              <CardContent>
                <Grid container spacing={0.5}>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInput
                      label="Email Comercial"
                      source="commercialEmail"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextInput
                      label="Telefone Comercial"
                      source="commercialPhoneNumber"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextInput
                      label="Endereço Comercial"
                      source="commercialAddress"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="Cidade Comercial"
                      source="commercialAddressCity"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="Bairro"
                      source="commercialAddressNeighborhood"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="Estado Comercial"
                      source="commercialAddressState"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextInput
                      label="CEP Comercial"
                      source="commercialAddressZipCode"
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid lg={4} xs={12} item>
            <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
              <CardHeader title="Arquivos e Mídia" />
              <CardContent>
                <Grid item xs={12}>
                  <div style={{ display: "none" }}>
                    <JsonInput source="physicalCardPicture" label="Foto" />
                  </div>
                  <FileUploadComponent
                  // save={controller.save}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Formações" />
          <CardContent>
            {orphanedId && (
              <Alert severity="warning">
                <AlertTitle>ATENÇÃO</AlertTitle>Auto-popular por membro órfão
                não contempla relacionamentos como FORMAÇÕES, favor adicionar
                manualmente.
              </Alert>
            )}

            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ArrayInput
                  source="userGraduations"
                  label="Graduações do Membro">
                  <SimpleFormIterator
                    inline
                    fullWidth
                    getItemLabel={(index) => `#${index + 1}`}>
                    <ReferenceInput
                      source="graduationId"
                      reference="graduations/crud"
                      sort={{ field: "reviewed", order: "DESC" }}
                      perPage={3000}
                      // enableGetChoices={({ name }) => name && name.length >= 2}
                    >
                      <AutocompleteInput
                        label="Graduação"
                        optionText="name"
                        optionValue="id"
                        isRequired
                        suggestionLimit={50}
                        create={<CreateGraduation />}
                        // sort={{ field: 'name', order: 'ASC' }}
                        filterToQuery={filterToQuery}
                        sx={{ minWidth: "50vw" }}
                      />
                    </ReferenceInput>

                    <ReferenceInput
                      source="educationalInstitutionId"
                      reference="educational-institutions/crud"
                      perPage={3000}
                      // enableGetChoices={({ name }) => name && name.length >= 2}
                    >
                      <AutocompleteInput
                        label="Instituição de Ensino"
                        optionText="name"
                        optionValue="id"
                        suggestionLimit={50}
                        create={<CreateEducationalInstitution />}
                        isRequired
                        filterToQuery={filterToQuery}
                        // sx={{ width: "40ch" }}
                      />
                    </ReferenceInput>
                    <NumberInput
                      source="yearCompletion"
                      label="Ano de Conclusão"
                      isRequired
                      helperText={false}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Divisões técnicas" />
          <CardContent>
            {orphanedId && (
              <Alert severity="warning">
                <AlertTitle>ATENÇÃO</AlertTitle>Auto-popular por membro órfão
                não contempla relacionamentos como DTEs, favor adicionar
                manualmente.
              </Alert>
            )}

            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ReferenceArrayInput
                  reference="technical-divisions/crud"
                  source="technicalDivisions">
                  <AutocompleteArrayInput
                    optionText="name"
                    optionValue="id"
                    label="Divisões Técnicas"
                    filterToQuery={filterToQuery}
                    helperText={
                      "Selecione até 2 divisões técnicas. " +
                      "Deixe em branco caso não se aplique. "
                    }
                    // create={<TechDivisionCreate showListActions={false} />}
                  />
                  {/* //TODO CREATION NOT WORKING */}
                  {/* //TODO NOT SELECTING EXISTING */}
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Informações do Associado" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="registrationNumber"
                  label="Matrícula"
                  helperText={false}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <TextInput
                  source="registrationNumberProv"
                  label="Matrícula Provisória"
                  helperText={false}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <SelectInput
                  source="accountStatus"
                  label="Situação da Conta"
                  fullWidth
                  helperText={false}
                  choices={[
                    { id: "ACTIVE", name: "Ativa" },
                    { id: "DECEASED", name: "Falecido" },
                    { id: "CANCELED", name: "Cancelado" },
                    { id: "LICENSED", name: "Licenciado" },
                    { id: "PENDING", name: "Pendente" },
                    { id: "NO_CONTACT", name: "Sem Contato" },
                    { id: "UNKNOWN", name: "Desconhecido" },
                  ]}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <ReferenceInput
                  source="userCategoryId"
                  reference="user-categories/crud"

                  // perPage={10}
                  // enableGetChoices={({ name }) => name && name.length >= 2}
                >
                  <AutocompleteInput
                    label="Categoria do Membro"
                    optionText="name"
                    optionValue="id"
                    fullWidth
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <ReferenceInput
                  source="roleId"
                  reference="user-roles/crud"

                  // perPage={10}
                  // enableGetChoices={({ name }) => name && name.length >= 2}
                >
                  <AutocompleteInput
                    label="Regra de Usuário"
                    optionText="name"
                    optionValue="id"
                    fullWidth
                    helperText={false}
                    disabled={
                      determineUserReadableRole(userRole) === "admin"
                        ? false
                        : true
                    }
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </CreateContextProvider>
  );
};
