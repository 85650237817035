import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  Labeled,
  useEditController,
  FunctionField,
} from "react-admin";
import { useParams } from "react-router-dom";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FaSave } from "react-icons/fa";
export const OrphanedUsersShow = () => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "orphaned-users/crud",
    id,
  });
  return (
    <ShowContextProvider value={controller}>
      <SimpleShowLayout className="RaShowClass">
        <PageTopBreadcrumb
          title="Membros Órfãos"
          firstPath="/#/orphaned-users/crud"
          secondTitle="Visualizando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="edit"
        />
        <Card sx={{ padding: 2, marginBottom: 3 }}>
          <CardHeader title="Visualizando" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <FunctionField
                  render={(record: any) => {
                    let newElement: any;
                    // for each property in record create a labeled textfield

                    return (
                      <Stack>
                        {record &&
                          Object.keys(record).map((key) => {
                            return (
                              <Labeled>
                                <TextField source={key} />
                              </Labeled>
                            );
                          })}
                      </Stack>
                    );
                  }}
                />
                <Stack>
                  {/* <Labeled>
                    <TextField source="id" />
                  </Labeled>
                  <Labeled>
                    <TextField source="oldId" />
                  </Labeled>
                  <Labeled>
                    <TextField source="sNome" />
                  </Labeled>
                  <Labeled>
                    <FunctionField
                      label="Matrícula"
                      render={(record: any) => (
                        <>{record.nMatricula + "-" + record.nDigito}</>
                      )}
                    />
                  </Labeled>
                  <Labeled>
                    <TextField source="sNome" />
                  </Labeled>
                  <Labeled>
                    <TextField source="Escrit_id" />
                  </Labeled>
                  <Labeled>
                    <TextField source="Categoria" />
                  </Labeled>
                  <Labeled>
                    <TextField source="Categoria" />
                  </Labeled> */}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <FunctionField
          render={(record: any) => (
            <Button
              variant="outlined"
              startIcon={<FaSave />}
              onClick={() => {
                window.location.href = `/#/users/crud/create/${record.id}`;
              }}
            >
              Criar Sócio
            </Button>
          )}
        />
      </SimpleShowLayout>
    </ShowContextProvider>
  );
};
