import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  useEditController,
  AutocompleteInput,
} from "react-admin";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

export const UserElectionEdit = (props: any) => {
  const { id } = useParams();
  const controller = useEditController({
    mutationMode: "pessimistic",
    resource: "user-elections/crud",
    id,
  });
  return (
    <Edit>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Eleições"
          firstPath="/#/user-elections/crud"
          secondTitle="Editando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="show"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader
            title="Eleições"
            // subheader="September 14, 2016"
          />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <TextInput source="id" fullWidth disabled helperText={false} />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="name"
                  label="Eleição"
                  fullWidth
                  helperText={false}
                />
                <BooleanInput
                  source="participated"
                  label="Participou na eleição"
                  fullWidth
                  helperText={false}
                />
                <NumberInput
                  source="year"
                  label="Ano"
                  fullWidth
                  helperText={false}
                />
                <ReferenceInput source="userId" reference="users/crud">
                  <AutocompleteInput
                    label="Nome/E-mail/CPF"
                    optionText="fullName"
                    optionValue="id"
                    fullWidth
                    helperText={false}
                    filterToQuery={(searchText: string) => ({
                      mailName: searchText,
                    })}
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </Edit>
  );
};
