import { useState } from "react";
import {
  AutocompleteArrayInput,
  Form,
  ReferenceArrayInput,
  ArrayInput,
  DateInput,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
} from "react-admin";
import { JsonInput } from "react-admin-json-view";
import CircularProgress from "@mui/material/CircularProgress";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { validateUserResource } from "../../ra-components/users/UserValidation";
import { PageTopBreadcrumb } from "../PageTopBreadcrumb";
import { formatCPF } from "../../ra-components/users/CPFUtils";
import { ZIPComponent } from "../../ra-components/users/ZIPComponent";
import Button from "@mui/material/Button";
import FileUploadComponent from "../../ra-components/users/FileUploadComponent";
import { CreateGraduation } from "../../ra-components/users/CreateGraduationModal";
import { CreateEducationalInstitution } from "../../ra-components/users/CreateEducationalInstitutionModal";
import { useParams } from "react-router-dom";
// import { default as TextInputMUI } from "@mui/material/TextField";

export const RegisterPageV2 = (props: any) => {
  const notify = useNotify();
  const [selectedDTEs, setSelectedDTEs] = useState<any>([]);
  const handleChange = (changes: any) => {
    // console.log("Field changes:", changes);
    setSelectedDTEs(changes);
  };

  // const controller: any = useCreateController({ resource: "auth/register" });
  const filterToQuery = (searchText: any) => ({
    name: `${searchText}`,
    // reviewed: "true",
  });
  return (
    <Form
      validate={validateUserResource}
      onSubmit={async (data) => {
        // console.log("submit", data);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_PATH}/auth/register`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: { "Content-Type": "application/json" },
            }
          );
          const responseData = await response.json();
          if (!response.ok) {
            // console.log("response not OK!", responseData, response);
            const errorMessage = responseData.message || response.statusText;
            notify(errorMessage, { type: "error" });
          }
          if (response.status >= 200 && response.status < 300) {
            notify("Sócio criado com sucesso!", { type: "success" });
            setTimeout(() => {
              //@ts-ignore
              window.location = "/#/register/success";
            }, 3000);
          }
          // return responseData;
        } catch (error: any) {
          notify(error, { type: "error" });
        }
      }}>
      <PageTopBreadcrumb
        title="Cadastro de Sócio"
        firstPath="registrar"
        secondTitle="Novo"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardHeader
          title="Informações Pessoais"
          // subheader="September 14, 2016"
        />
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <TextInput
                required
                label="Nome"
                source="fullName"
                fullWidth
                // {...register("fullName")}
                helperText={false}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <TextInput
                required
                source="email"
                label="Email Particular"
                type="email"
                fullWidth
                helperText={false}
              />
            </Grid>
            {/* <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="login"
                label="login"
                fullWidth
                helperText={false}
              />
            </Grid> */}
            <Grid item lg={3} md={6} xs={12}>
              <TextInput
                source="password"
                label="Senha"
                fullWidth
                required
                type="password"
                helperText={false}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <TextInput
                required
                source="cpf"
                label="CPF"
                format={(v: any) => formatCPF(v)}
                // parse={(v: any) => {
                //   validarCPF(v);
                // }}
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <TextInput
                source="CREA"
                label="CREA"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                source="professionalActivity"
                label="Atividade Profissional Principal"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <DateInput
                source="birthDate"
                label="Data de Nascimento"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <SelectInput
                source="gender"
                label="Sexo"
                fullWidth
                helperText={false}
                choices={[
                  { id: "M", name: "Masculino" },
                  { id: "F", name: "Feminino" },
                ]}
                // onChange={(e: any) => {
                //   console.log(
                //     "formChange",
                //     e.target.name,
                //     e.target.value,
                //     formRecords
                //   );
                //   setFormRecords({
                //     ...formRecords,
                //     [e.target.name]: e.target.value,
                //   });
                // }}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <SelectInput
                source="maritalStatus"
                label="Estado Civil"
                fullWidth
                helperText={false}
                choices={[
                  { id: "SOLTEIRO", name: "Solteiro(a)" },
                  { id: "CASADO", name: "Casado(a)" },
                  { id: "DESQUITADO", name: "Desquitado(a)" },
                  { id: "DIVORCIADO", name: "Divorciado(a)" },
                  { id: "VIUVO", name: "Viúvo(a)" },
                  { id: "UNIAO_ESTAVEL", name: "União Estável" },
                ]}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="phoneNumber"
                label="Celular"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="residencePhoneNumber"
                label="Telefone Residencial"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="addressState"
                label="Estado"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextInput
                source="address"
                label="Endereço"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="addressCity"
                label="Cidade"
                fullWidth
                helperText={false}
              />
            </Grid>

            <Grid item lg={2} md={6} xs={12}>
              <ZIPComponent />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="addressNeighborhood"
                label="Bairro"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <TextInput
                source="addressRegion"
                label="Região"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={12}>
              <ArrayInput source="dependents" label="Dependentes">
                <SimpleFormIterator
                  inline
                  fullWidth
                  getItemLabel={(index) => `#${index + 1}`}>
                  <TextInput source="name" label="Nome" />
                  <TextInput source="birthDate" label="Nascimento" />
                  <TextInput source="kinship" label="Parentesco" />
                  {/* <TextInputMUI
                      name={`dependents[${index}].name`}
                      label={`Nome do Dependente #${index + 1}`}
                      fullWidth
                      helperText={false}
                    /> */}
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={0.5}>
        <Grid lg={8} xs={12} item>
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Informações Comerciais" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item lg={4} md={6} xs={12}>
                  <TextInput
                    label="Email Comercial"
                    source="commercialEmail"
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item lg={2} md={6} xs={12}>
                  <TextInput
                    label="Telefone Comercial"
                    source="commercialPhoneNumber"
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextInput
                    label="Endereço Comercial"
                    source="commercialAddress"
                    fullWidth
                    helperText={false}
                  />
                </Grid>

                <Grid item lg={3} md={6} xs={12}>
                  <TextInput
                    label="Cidade Comercial"
                    source="commercialAddressCity"
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <TextInput
                    label="Bairro"
                    source="commercialAddressNeighborhood"
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <TextInput
                    label="Estado Comercial"
                    source="commercialAddressState"
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <TextInput
                    label="CEP Comercial"
                    source="commercialAddressZipCode"
                    fullWidth
                    helperText={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid lg={4} xs={12} item>
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Arquivos e Mídia" />
            <CardContent>
              <Grid item xs={12}>
                <div style={{ display: "none" }}>
                  <JsonInput source="physicalCardPicture" label="Foto" />
                </div>
                <FileUploadComponent />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardHeader title="Formações" />
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <ArrayInput source="userGraduations" label="Graduações do Membro">
                <SimpleFormIterator
                  inline
                  fullWidth
                  getItemLabel={(index) => `#${index + 1}`}>
                  <ReferenceInput
                    source="graduationId"
                    reference="graduations/crud"
                    sort={{ field: "reviewed", order: "DESC" }}
                    perPage={3000}
                    // enableGetChoices={({ name }) => name && name.length >= 2}
                  >
                    <AutocompleteInput
                      label="Graduação"
                      optionText="name"
                      optionValue="id"
                      shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                      }}
                      suggestionLimit={50}
                      isRequired
                      create={<CreateGraduation />}
                      // onChange={handleGraduationChange}
                      // value={selectedGraduations}
                      // defaultValue={selectedGraduations}
                      // disabled={selectedGraduations.length >= 2}
                      // sort={{ field: 'name', order: 'ASC' }}
                      // debounce={false}
                      filterToQuery={filterToQuery}
                      sx={{ minWidth: "50vw" }}
                      isLoading={true}
                    />
                  </ReferenceInput>

                  <ReferenceInput
                    source="educationalInstitutionId"
                    reference="educational-institutions/crud"
                    perPage={3000}

                    // perPage={10}
                    // enableGetChoices={({ name }) => name && name.length >= 2}
                  >
                    <AutocompleteInput
                      label="Instituição de Ensino"
                      optionText="name"
                      optionValue="id"
                      isRequired
                      suggestionLimit={50}
                      filterToQuery={filterToQuery}
                      create={<CreateEducationalInstitution />}

                      // sx={{ width: "40ch" }}
                    />
                  </ReferenceInput>
                  <NumberInput
                    source="yearCompletion"
                    label="Ano de Conclusão"
                    isRequired
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardHeader title="Divisões técnicas" />
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <ReferenceArrayInput
                reference="technical-divisions/crud"
                source="technicalDivisions">
                <AutocompleteArrayInput
                  optionText="name"
                  optionValue="id"
                  label="Divisões Técnicas"
                  filterToQuery={filterToQuery}
                  onChange={handleChange}
                  disabled={selectedDTEs.length >= 2}
                  helperText={
                    "Selecione até 2 divisões técnicas. Alterações futuras somente mediante solicitação ao administrativo." +
                    "Deixe em branco caso não se aplique. "
                  }
                />
              </ReferenceArrayInput>
              {/* If DTE is disable, show button to reset */}
              {selectedDTEs.length >= 2 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setSelectedDTEs([]);
                    // empties the AutoCompleteArrayInput, clicks on a button element with class .MuiAutocomplete-clearIndicator
                  }}>
                  Corrigir seleção
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Registrar
      </Button>
    </Form>
  );
};

export const RegisterPageSuccess = () => {
  return (
    <>
      <Grid container spacing={0.5}>
        <Grid item sm={6} xs={12}>
          <Card sx={{ padding: 2, marginBottom: 3 }}>
            <CardHeader
              title={"Registrado com sucesso!"}
              subheader={"Aguarde a confirmação administrativa."}
            />
            <CardContent>
              <Button
                variant="contained"
                onClick={() => {
                  window.location.href = "/#/login";
                }}>
                Login
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const RecoveryPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useParams();
  const notify = useNotify();
  const handleSubmitToken = async (data: any) => {
    // console.log("submit", data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/auth/forgot-password`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        // console.log("response not OK!", responseData, response);
        const errorMessage = responseData.message || response.statusText;
        // notify(errorMessage, { type: "error" });
        notify("Erro ao recuperar senha.", { type: "error" });
      }
      if (response.status >= 200 && response.status < 300) {
        notify("Senha atualizada com sucesso!", {
          type: "success",
        });
        setTimeout(() => {
          //@ts-ignore
          window.location = "/#/login";
        }, 3000);
      }
      // return responseData;
    } catch (error: any) {
      notify("Erro ao recuperar senha.", { type: "error" });
    }
  };

  const handleSubmitMail = async (data: any) => {
    // console.log("submit", data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/auth/reset-password`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
        }
      );
      // const responseData = await response.json();
      if (!response.ok) {
        // const errorMessage =
        //   responseData.message || response.statusText;
        notify("Erro ao recuperar senha.", { type: "error" });
      }
      if (response.status >= 200 && response.status < 300) {
        notify(
          "Solicitação de recuperação de senha enviada. Confira seu e-mail.",
          {
            type: "success",
          }
        );
        setTimeout(() => {
          //@ts-ignore
          window.location = "/#/login";
        }, 3000);
      }
      // return responseData;
    } catch (error: any) {
      notify("Erro ao solicitar recuperação de senha.", {
        type: "error",
      });
    }
  };
  // console.log("params", useParams());
  return (
    <>
      <Grid container spacing={0.5}>
        <Grid item sm={6} xs={12}>
          <Card sx={{ padding: 2, marginBottom: 3 }}>
            <CardHeader
              title={"Recuperar Senha"}
              subheader={"Insira sua nova senha para salvar a alteração."}
            />
            {token && (
              <CardContent>
                <Form
                  onSubmit={async (data) => {
                    setIsLoading(true);
                    await handleSubmitToken(data);
                    setIsLoading(false);
                  }}>
                  <TextInput
                    source="token"
                    label="Token"
                    defaultValue={token}
                    fullWidth
                    disabled
                    helperText={false}
                  />
                  <TextInput
                    source="password"
                    label="Nova Senha"
                    type="password"
                    fullWidth
                    helperText={false}
                  />
                  <Button
                    type="submit"
                    disabled={isLoading}
                    variant="contained"
                    color="primary">
                    {isLoading && (
                      <CircularProgress sx={{ color: "white" }} size={18} />
                    )}{" "}
                    Salvar nova senha
                  </Button>
                </Form>
              </CardContent>
            )}
            {!token && (
              <CardContent>
                <Form
                  onSubmit={async (data) => {
                    setIsLoading(true);
                    await handleSubmitMail(data);
                    setIsLoading(false);
                  }}>
                  <TextInput
                    source="email"
                    label="Email"
                    fullWidth
                    helperText={false}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}>
                    {isLoading && (
                      <CircularProgress sx={{ color: "white" }} size={18} />
                    )}{" "}
                    Recuperar Senha
                  </Button>
                </Form>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
