import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { useFormContext } from "react-hook-form";
import { formatCPF } from "./CPFUtils";
import { parseDateTime } from "./UserUtils";
import { FaCodepen } from "react-icons/fa";

export const AutoPopulateUser = (props: any) => {
  const { reset, setValue, getValues } = useFormContext();

  const populateFields = async () => {
    try {
      const auth = localStorage.getItem("auth");
      const authJson = auth ? JSON.parse(auth) : auth;
      // console.log("fetch orphaned member");
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/orphaned-users/crud/${props.orphanedId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authJson.access_token,
          },
        }
      );
      const data = await response.json();
      // console.log("data", data);
      if (response.status !== 200) {
        throw new Error("Erro ao buscar membro órfão");
      }
      setValue("fullName", data.sNome);
      setValue("email", data.sEmailPart);
      setValue("cpf", formatCPF(data.nCPF));
      setValue("login", data.login);
      setValue("CREA", data.sCREA);
      setValue("password", data.senha);
      setValue("professionalActivity", data.sAtividade);
      setValue("birthDate", parseDateTime(data.dDtNasc));
      setValue("gender", data.sSexo);
      const estadoCivilProcess: any =
        data["sEstadoCivil"] === "SOLTEIRO" ||
        data["sEstadoCivil"] === "SOLTEIRO(A)"
          ? "SOLTEIRO"
          : data["sEstadoCivil"] === "CASADO" ||
            data["sEstadoCivil"] === "CASADO(A)"
          ? "CASADO"
          : data["sEstadoCivil"] === "DIVORCIADO" ||
            data["sEstadoCivil"] === "DIVORCIADO(A)"
          ? "DIVORCIADO"
          : data["sEstadoCivil"] === "VIUVO" ||
            data["sEstadoCivil"] === "VIUVO(A)"
          ? "VIUVO"
          : data["sEstadoCivil"] === "UNIAO ESTAVEL"
          ? "UNIAO_ESTAVEL"
          : data["sEstadoCivil"] === "DESQUITADO" ||
            data["sEstadoCivil"] === "DESQUITADO(A)"
          ? "DESQUITADO"
          : "";
      setValue("maritalStatus", estadoCivilProcess);
      setValue("phoneNumber", data.sCelular);
      setValue("residencePhoneNumber", data.sTelResid);
      setValue("addressState", data.sEstResid);
      setValue("addressCity", data.sCidResid);
      setValue("addressNeighborhood", data.sBaiResid);
      setValue("address", data.sEndResid);
      setValue("addressRegion", data.sFaixaCep);
      setValue("addressZipCode", data.nCepResid);
      const dependentList: any = data["sDepende"];
      const dependentKinshipList: any = data["sParente"];
      const dependentBirthDateList: any = data["dNascDep"];
      const dependentListArray = dependentList.split(",");
      const dependentKinshipListArray = dependentKinshipList.split(",");
      const dependentBirthDateListArray = dependentBirthDateList.split(",");
      // remove empty
      const dependentArray = dependentListArray.filter((item: any) => item);
      const dependentKinshipArray = dependentKinshipListArray.filter(
        (item: any) => item
      );
      const dependentBirthDateArray = dependentBirthDateListArray.filter(
        (item: any) => item
      );
      // Prepare a JSON array of dependents for dependents field
      const dependents: any = [];
      for (let i = 0; i < dependentArray.length; i++) {
        dependents.push({
          name: dependentArray[i],
          birthDate: dependentBirthDateArray[i],
          kinship: dependentKinshipArray[i],
        });
      }
      setValue("dependents", dependents);
      setValue("commercialEmail", data.sEmailCome);
      setValue("commercialPhoneNumber", data.sTelComer);
      setValue("commercialAddress", data.sEndComer);
      setValue("commercialAddressCity", data.sCidComer);
      setValue("commercialAddressState", data.sEstComer);
      setValue("commercialAddressZipCode", data.sCepComer);
      setValue("commercialAddressNeighborhood", data.sBaiComer);

      // dtes

      setValue(
        "accountStatus",
        data["sSituacao"] === "A"
          ? "ACTIVE"
          : data["sSituacao"] === "C"
          ? "CANCELED"
          : data["sSituacao"] === "D"
          ? "NO_CONTACT"
          : data["sSituacao"] === "F"
          ? "DECEASED"
          : data["sSituacao"] === "L"
          ? "LICENSED"
          : "UNKNOWN"
      );
    } catch (error) {
      console.error("Erro ao buscar membro órfão: ", error);
      return null;
    }
  };
  return (
    <>
      {props.orphanedId && (
        <Alert severity="info">
          <AlertTitle>AUTO-PREENCHER SÓCIO (MEMBROS ÓRFÃOS)</AlertTitle>Deseja
          popular os campos com os dados do membro órfão de ID{" "}
          <b>
            <a href={`/#/orphaned-users/crud/${props.orphanedId}/show`}>
              {props.orphanedId}
            </a>
          </b>
          ? <br />
          ATENÇÃO: Recurso de auto-preencher é limitado aos campos que não
          possuem relacionamentos, os campos de "Formações", "Divisões Técnicas"
          e "Categoria do Membro" devem ser informados manualmente.
          <br />
          <Button
            variant="outlined"
            onClick={() => populateFields()}
            startIcon={<FaCodepen />}>
            Auto-preencher
          </Button>
        </Alert>
      )}
    </>
  );
};
