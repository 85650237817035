import React, { useState } from "react";
import {
  HiCurrencyDollar,
  HiLogout,
  HiOutlineCash,
  HiOutlineChartBar,
  HiOutlineChat,
  HiViewList,
  HiDocumentAdd,
  HiOutlineTrendingUp,
  HiOutlineUserCircle,
  HiOutlineUsers,
  HiOutlineViewBoards,
  HiOutlineViewGrid,
  HiRefresh,
  HiUser,
  HiUsers,
  HiCog,
  HiUserGroup,
  HiMail,
  HiAdjustments,
  HiLogin,
} from "react-icons/hi";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SchoolIcon from "@mui/icons-material/School";
import { FaUniversity, FaIdCard } from "react-icons/fa";
import { BiBuoy } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { List, Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { UserMenu, useLogout, useUserMenu } from "react-admin";
import BallotIcon from "@mui/icons-material/Ballot";
import { determineUserReadableRole } from "../ra-components/users/UserUtils";
import "../index.css";
const SettingsMenuItem = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const logout = useLogout();
  const auth = localStorage.getItem("auth");
  if (auth) {
    const authJson = auth ? JSON.parse(auth) : auth;
    const id = authJson.id;

    const handleLogout = () => {
      logout();
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    };
    return (
      <List onClick={onClose} {...props}>
        <ListItemButton href={"#/users/crud/" + id + "/show"}>
          Perfil
        </ListItemButton>
        {/* <ListItemButton>Configuração</ListItemButton> */}
        <ListItemButton onClick={handleLogout}>Sair</ListItemButton>
      </List>
    );
  } else {
    return (
      <List onClick={onClose} {...props}>
        <ListItemButton href={"#/login"}>Login</ListItemButton>
        {/* <ListItemButton>Configuração</ListItemButton> */}
      </List>
    );
  }
});

export function AppUserMenu() {
  return (
    <Box>
      <UserMenu className="userMenuAddress" icon={<HiOutlineUserCircle />}>
        <SettingsMenuItem />
      </UserMenu>
    </Box>
  );
}

export function AppSidebarMenu() {
  //choose the screen size
  const [open, setOpen] = useState("members");

  const handleMenuCollapse = (subMenu: string) => {
    setOpen(subMenu);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transactionType = queryParams.get("type");
  const currentPath = location.pathname;
  const logout = useLogout();
  const handleLogout = () => {
    logout();
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  };
  const isActive = (path: string) => {
    return currentPath === path;
  };
  // /
  const roles = localStorage.getItem("roles");
  const auth = localStorage.getItem("auth");
  const authJson = auth ? JSON.parse(auth) : null;
  if (
    determineUserReadableRole(roles) === "admin" ||
    determineUserReadableRole(roles) === "finance" ||
    determineUserReadableRole(roles) === "secretary" ||
    determineUserReadableRole(roles) === "comms"
  ) {
    return (
      <>
        <List
          sx={{ width: "100%", maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
          // }
        >
          {(determineUserReadableRole(roles) === "admin" ||
            determineUserReadableRole(roles) === "finance") && (
            <ListItemButton
              selected={isActive("/dashboard")}
              onClick={() => {
                window.location.href = "/#/dashboard";
              }}>
              <ListItemIcon>
                <HiOutlineChartBar size={24} color="#fff" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          )}
          <ListItemButton onClick={() => handleMenuCollapse("members")}>
            <ListItemIcon>
              <HiUserGroup size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Gestão de Membros" />
            {open === "members" ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open === "members"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={isActive("/users/crud")}
                onClick={() => {
                  window.location.href = "/#/users/crud";
                }}>
                <ListItemIcon>
                  <HiUserGroup size={24} color="#fff" />
                </ListItemIcon>
                <ListItemText primary="Sócios" />
              </ListItemButton>
              {determineUserReadableRole(roles) === "admin" && (
                <>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/orphaned-users/crud")}
                    onClick={() => {
                      window.location.href = "/#/orphaned-users/crud";
                    }}>
                    <ListItemIcon>
                      <HiOutlineUsers size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Membros Órfãos" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/user-elections/crud")}
                    onClick={() => {
                      window.location.href = "/#/user-elections/crud";
                    }}>
                    <ListItemIcon>
                      <BallotIcon sx={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Eleições" />
                  </ListItemButton>
                </>
              )}
            </List>
          </Collapse>
          {determineUserReadableRole(roles) === "admin" && (
            <>
              <ListItemButton
                onClick={() => handleMenuCollapse("relationships")}>
                <ListItemIcon>
                  <HiAdjustments size={24} color="#fff" />
                </ListItemIcon>
                <ListItemText primary="Gestão de Relacionamentos" />
                {open === "relationships" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={open === "relationships"}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/user-position-roles/crud")}
                    onClick={() => {
                      window.location.href = "/#/user-position-roles/crud";
                    }}>
                    <ListItemIcon>
                      <FaIdCard size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Cargos e Funções" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/user-role-status/crud")}
                    onClick={() => {
                      window.location.href = "/#/user-role-status/crud";
                    }}>
                    <ListItemIcon>
                      <FaIdCard size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Status de Cargos e Funções" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/user-categories/crud")}
                    onClick={() => {
                      window.location.href = "/#/user-categories/crud";
                    }}>
                    <ListItemIcon>
                      <HiOutlineViewBoards size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Categorias de Sócios" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/technical-divisions/crud")}
                    onClick={() => {
                      window.location.href = "/#/technical-divisions/crud";
                    }}>
                    <ListItemIcon>
                      <HiOutlineViewBoards size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Divisões Técnicas" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/graduations/crud")}
                    onClick={() => {
                      window.location.href = "/#/graduations/crud";
                    }}>
                    <ListItemIcon>
                      <SchoolIcon sx={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Formações" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/educational-institutions/crud")}
                    onClick={() => {
                      window.location.href = "/#/educational-institutions/crud";
                    }}>
                    <ListItemIcon>
                      <FaUniversity size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Instituições de Ensino" />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}

          {(determineUserReadableRole(roles) === "admin" ||
            determineUserReadableRole(roles) === "finance") && (
            <>
              <ListItemButton
                onClick={() => handleMenuCollapse("transactions")}>
                <ListItemIcon>
                  <HiOutlineCash size={24} color="#fff" />
                </ListItemIcon>
                <ListItemText primary="Transações" />
                {open === "transactions" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={open === "transactions"}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={transactionType === "income"}
                    onClick={() => {
                      window.location.href = "/#/transactions/crud?type=income";
                    }}>
                    <ListItemIcon>
                      <HiOutlineTrendingUp size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Receitas & Despesas" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/transaction-categories/crud")}
                    onClick={() => {
                      window.location.href = "/#/transaction-categories/crud";
                    }}>
                    <ListItemIcon>
                      <HiOutlineViewBoards size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Categorias" />
                  </ListItemButton>
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleMenuCollapse("iugu")}>
                <ListItemIcon>
                  <HiOutlineCash size={24} color="#fff" />
                </ListItemIcon>
                <ListItemText primary="Iugu (Integração)" />
                {open === "iugu" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open === "iugu"} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/subscriptions")}
                    onClick={() => {
                      window.location.href = "/#/subscriptions";
                    }}>
                    <ListItemIcon>
                      <HiRefresh size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Assinaturas" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/iugu-customers")}
                    onClick={() => {
                      window.location.href = "/#/iugu-customers";
                    }}>
                    <ListItemIcon>
                      <HiUsers size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Clientes" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/iugu-planos")}
                    onClick={() => {
                      window.location.href = "/#/iugu-planos";
                    }}>
                    <ListItemIcon>
                      <HiOutlineViewGrid size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Planos" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/iugu-invoices")}
                    onClick={() => {
                      window.location.href = "/#/iugu-invoices";
                    }}>
                    <ListItemIcon>
                      <HiOutlineViewGrid size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Faturas" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={isActive("/products/crud")}
                    onClick={() => {
                      window.location.href = "/#/products/crud";
                    }}>
                    <ListItemIcon>
                      <HiViewList size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Itens de Cobrança" />
                  </ListItemButton>
                </List>
              </Collapse>
              {determineUserReadableRole(roles) === "admin" && (
                <>
                  <ListItemButton onClick={() => handleMenuCollapse("system")}>
                    <ListItemIcon>
                      <HiCog size={24} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Sistema" />
                    {open === "system" ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open === "system"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={isActive("/mail-templates/crud")}
                        onClick={() => {
                          window.location.href = "/#/mail-templates/crud";
                        }}>
                        <ListItemIcon>
                          <HiMail size={24} color="#fff" />
                        </ListItemIcon>
                        <ListItemText primary="Templates de E-mail" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={isActive("/system-settings/crud")}
                        onClick={() => {
                          window.location.href = "/#/system-settings/crud";
                        }}>
                        <ListItemIcon>
                          <HiCog size={24} color="#fff" />
                        </ListItemIcon>
                        <ListItemText primary="Configurações de Sistema" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={isActive("/user-roles/crud")}
                        onClick={() => {
                          window.location.href = "/#/user-roles/crud";
                        }}>
                        <ListItemIcon>
                          <HiUserGroup size={24} color="#fff" />
                        </ListItemIcon>
                        <ListItemText primary="Regras de Usuário" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              )}
            </>
          )}
        </List>
        <Divider />
        {determineUserReadableRole(roles) === "admin" && (
          <List>
            <ListItemButton
              selected={isActive("/feedbacks/crud")}
              onClick={() => {
                window.location.href = "/#/feedbacks/crud";
              }}>
              <ListItemIcon>
                <HiOutlineChat size={24} color="#fff" />
              </ListItemIcon>
              <ListItemText primary="Contatos" />
            </ListItemButton>
            <ListItemButton
              selected={isActive("/api")}
              onClick={() => {
                window.location.href = "/#/api";
              }}>
              <ListItemIcon>
                <BiBuoy size={24} color="#fff" />
              </ListItemIcon>
              <ListItemText primary="Para o desenvolvedor" />
            </ListItemButton>
          </List>
        )}
      </>
    );
  } else if (determineUserReadableRole(roles) === "user") {
    return (
      <>
        <List>
          <ListItemButton
            selected={isActive("/users-crud")}
            onClick={() => {
              window.location.href = "/#/users/crud/" + authJson.id + "/show";
            }}>
            <ListItemIcon>
              <HiUser size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Meu Cadastro" />
          </ListItemButton>
          {/* // invoices */}
          <ListItemButton
            selected={isActive("/my-invoices")}
            onClick={() => {
              window.location.href = "/#/my-invoices";
            }}>
            <ListItemIcon>
              <HiCurrencyDollar size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Minhas Cobranças" />
          </ListItemButton>
          <ListItemButton
            selected={isActive("/my-subscriptions")}
            onClick={() => {
              window.location.href = "/#/my-subscriptions";
            }}>
            <ListItemIcon>
              <HiRefresh size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Minhas Assinaturas" />
          </ListItemButton>
          <ListItemButton
            selected={isActive("/feedbacks/crud/create")}
            onClick={() => {
              window.location.href = "/#/feedbacks/crud/create";
            }}>
            <ListItemIcon>
              <HiOutlineChat size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Contato" />
          </ListItemButton>
          <ListItemButton selected={isActive("/logout")} onClick={handleLogout}>
            <ListItemIcon>
              <HiLogout size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItemButton>
        </List>
      </>
    );
  } else {
    return (
      <>
        <List>
          <ListItemButton
            selected={isActive("/login")}
            onClick={() => {
              window.location.href = "/#/login";
            }}>
            <ListItemIcon>
              <HiLogin size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItemButton>
          <ListItemButton
            selected={isActive("/register")}
            onClick={() => {
              window.location.href = "/#/register";
            }}>
            <ListItemIcon>
              <HiUser size={24} color="#fff" />
            </ListItemIcon>
            <ListItemText primary="Cadastro" />
          </ListItemButton>
        </List>
      </>
    );
  }
}
