import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export const IuguPagination = (props: any) => {
  const { page, setPage, list } = props;
  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  return (
    <Grid container spacing={0.5}>
      {/* Page: {page} */}
      <Grid item xs={6}>
        <Button
          variant="contained"
          onClick={handlePrevPage}
          disabled={page === 1}
          startIcon={<FaArrowLeft />}
        >
          Voltar Página
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          endIcon={<FaArrowRight />}
          variant="contained"
          disabled={list.length === 0}
          onClick={handleNextPage}
        >
          Avançar Página
        </Button>
      </Grid>
    </Grid>
  );
};
