import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  Labeled,
  useEditController,
  NumberField,
} from "react-admin";
import { useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Button from "@mui/material/Button";
import { FaEye } from "react-icons/fa";

export const TransactionsShow = (props: any) => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "transactions/crud",
    id,
  });

  return (
    <>
      <ShowContextProvider value={controller}>
        <SimpleShowLayout className="RaShowClass">
          <PageTopBreadcrumb
            title="Transações"
            firstPath="/#/transactions/crud"
            secondTitle="Visualizando"
            thirdTitle={controller && controller.record && controller.record.id}
            actionType="edit"
          />
          <Grid container spacing={0.5}>
            <Grid item sm={12} xs={12}>
              <Card sx={{ padding: 2, marginBottom: 3 }}>
                <FunctionField
                  render={(record: any) => (
                    <CardHeader
                      title={record.fullName}
                      subheader={"Transação: " + record.id}
                    />
                  )}
                />
                <CardContent>
                  <Grid container spacing={0.5}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack>
                        <Labeled>
                          <TextField source="id" label="ID da Transação" />
                        </Labeled>
                        <Labeled>
                          <ReferenceField
                            source="memberId"
                            label="Sócio"
                            reference="users/crud"
                            link="show"
                          >
                            <TextField source="fullName" label="Sócio" />
                          </ReferenceField>
                        </Labeled>
                        <Labeled>
                          <TextField
                            label="ID do Órfão"
                            source="orphanedMemberId"
                          />
                        </Labeled>
                        <Labeled>
                          <FunctionField
                            label="ID da Fatura IUGU"
                            render={(record: any) =>
                              !record.iuguId ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    variant="outlined"
                                    startIcon={<FaEye />}
                                    onClick={() => {
                                      window.location.href = `/#/iugu-invoices?iugu_id=${record.iuguId}`;
                                    }}
                                  >
                                    {record.iuguId}
                                  </Button>
                                </>
                              )
                            }
                          />
                        </Labeled>
                        <Labeled>
                          <FunctionField
                            label="Membro Órfão"
                            render={(record: any) =>
                              !record.orphanedMemberId ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    variant="outlined"
                                    startIcon={<FaEye />}
                                    onClick={() => {
                                      window.location.href = `/#/orphaned-users/crud/${record.orphanedMemberId}/show`;
                                    }}
                                  >
                                    {record.orphanedMemberId}
                                  </Button>
                                </>
                              )
                            }
                          />
                        </Labeled>
                        <Labeled>
                          <DateField
                            source="referenceDate"
                            label="Data Referência"
                            locales="pt-BR"
                          />
                        </Labeled>
                        <Labeled>
                          <ReferenceField
                            source="id_Receita"
                            reference="transaction-categories/crud"
                            label="Categoria da Receita"
                          >
                            <TextField source="name" label="Categoria" />
                          </ReferenceField>
                        </Labeled>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack>
                        <Labeled>
                          <NumberField source="value" label="Valor" />
                        </Labeled>
                        <Labeled>
                          <NumberField
                            source="paymentMethod"
                            label="Forma de Pagamento"
                          />
                        </Labeled>
                        <Labeled>
                          <NumberField source="cpf" label="CPF" />
                        </Labeled>
                        <Labeled>
                          <FunctionField
                            label="ID"
                            render={(record: any) => (
                              <p>{record.sCompetencia}</p>
                            )}
                          />
                        </Labeled>
                        <Labeled>
                          <TextField
                            source="memo"
                            multiline
                            label="Observações (Memo)"
                          />
                        </Labeled>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SimpleShowLayout>
      </ShowContextProvider>
    </>
  );
};
