import { useEffect, useState } from "react";
import moment from "moment";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, TextField } from "@mui/material";
import { IuguPagination } from "./IuguPagination";
import { truncateString } from "../../../ra-components/users/UserUtils";
import PaymentIcon from "@mui/icons-material/Payment";
import Divider from "@mui/material/Divider";
import { statusLabels } from "./iugu-utils";

const SubscriptionPage = () => {
  const queryUrl = window.location.href;
  // extract uid from url
  let customer_id = queryUrl.split("/").pop();
  customer_id = customer_id ? customer_id.split("=").pop() : "";

  if (customer_id === "subscriptions") {
    customer_id = "";
  }
  const [subscriptions, setSubscriptions] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [customerId, setCustomerId] = useState(customer_id || "");

  const getSubscriptions = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;
    const cidStr = customerId ? `&customer_id=${customerId}` : "";
    const qStr = query ? `&q=${query}` : "";

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/subscriptions?s=${start}&l=${perPage}${qStr}${cidStr}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ getSubscriptions: data });
    setSubscriptions(data.items);
    return data;
  };

  useEffect(() => {
    getSubscriptions();
  }, [page, query, customerId]);

  return (
    <>
      <PageTopBreadcrumb
        title="Assinaturas IUGU"
        firstPath="/#/subscriptions"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <TextField
            type="text"
            title="Pesquisar"
            placeholder="Pesquisar"
            helperText="E-mail, nome do cliente ou plano..."
            // defaultValue={iugu_id}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <TextField
            type="text"
            title="ID de Cliente"
            placeholder="ID de Cliente"
            defaultValue={customer_id || ""}
            onChange={(e) => {
              setCustomerId(e.target.value);
            }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Assinatura</TableCell>
                <TableCell>Cobranças</TableCell>
                {/* <TableCell>Valor</TableCell> */}
                <TableCell>Datas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription: any) => (
                <TableRow key={subscription.id}>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      title={subscription.id}
                      onClick={() => {
                        // window.location.href =
                        //   "/#/invoices/crud/" + invoice.id + "/show";
                      }}>
                      {truncateString(subscription.id, 13)}
                    </Button>
                    <Typography
                      variant="caption"
                      // onClick={() => {
                      //   window.location.href =
                      //     "/#/subscriptions/crud/" + subscription.id + "/show";
                      // }}
                    >
                      <br />
                      <b>Plano: </b>
                      {subscription.plan_name || "Desconhecido"}
                      <br />
                      <b>Situação da Assinatura: </b>
                      {subscription.active ? "Ativa" : "Não ativa"}
                      <br />
                      <b>Suspenção: </b>
                      {subscription.suspended ? "Ativa" : "Inativa"}
                      <br />
                      <b>Cliente: </b>
                      {subscription.customer_name || "Desconhecido"}
                      <br />
                      <b>Email: </b>
                      {subscription.customer_email || "Desconhecido"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {subscription.recent_invoices.map((item: any) => (
                      <>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Cobrança</TableCell>
                              <TableCell>Vencimento</TableCell>
                              <TableCell>Valor</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={item.id}>
                              <TableCell>
                                <Typography variant="caption">
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    title={item.id}
                                    onClick={() => {
                                      window.location.href =
                                        "/#/iugu-invoices?iugu_id=" + item.id;
                                    }}>
                                    {truncateString(item.id, 13)}
                                  </Button>
                                  <br />
                                  <b>
                                    Status:{" "}
                                    {statusLabels[item.status] || item.status}
                                  </b>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {moment(item.due_date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  title="Link de pagamento"
                                  startIcon={<PaymentIcon />}
                                  size="small"
                                  // disabled={item.status !== "pending"}
                                  target="_blank"
                                  href={item.secure_url}
                                  onClick={() => {
                                    // window.location.href = item.secure_url;
                                  }}>
                                  Ver fatura
                                </Button>
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Divider sx={{ marginTop: 1 }} />
                      </>
                    ))}
                  </TableCell>
                  {/* <TableCell>
                    R$ {(parseInt(subscription.price_cents) / 100).toFixed(2)}
                  </TableCell> */}
                  <TableCell>
                    <b>Emissão:</b>{" "}
                    {moment(subscription.createdAt).format("DD-MM-YYYY")}
                    <br />
                    {/* {subscription.referenceDate} */}
                    <b>Ciclo:</b>{" "}
                    {moment(subscription.cycled_at).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <IuguPagination setPage={setPage} page={page} list={subscriptions} />
    </>
  );
};

export default SubscriptionPage;
