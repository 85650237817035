import {
  List,
  DatagridConfigurable,
  TextField,
  TopToolbar,
  ExportButton,
  CreateButton,
  BooleanField,
  SelectColumnsButton,
  FilterButton,
  TextInput,
  SelectInput,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

export const GraduationList = () => {
  const postFilters = [
    <TextInput label="Nome" source="name" alwaysOn />,
    <SelectInput
      source="reviewed"
      label="Revisado"
      alwaysOn
      choices={[
        { id: "true", name: "Revisados" },
        { id: "false", name: "Não Revisados" },
      ]}
    />,
  ];
  const ListActions = () => (
    <TopToolbar>
      {/* <SelectColumnsButton /> */}
      <FilterButton />
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  );
  return (
    <>
      <PageTopBreadcrumb
        title="Formações"
        firstPath="/#/graduations/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List filters={postFilters}>
                <DatagridConfigurable>
                  {/* <TextField source="id" label="ID" /> */}
                  <TextField source="name" label="Nome" />
                  <BooleanField source="reviewed" label="Revisado" />
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
