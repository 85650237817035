import { useEffect, useState } from "react";
import moment from "moment";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardHeader,
  Button,
  TextField,
  CardContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import { IuguPagination } from "./IuguPagination";
import { truncateString } from "../../../ra-components/users/UserUtils";

const IuguCustomersPage = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [customers, setCustomers] = useState([]);
  const [query, setQuery] = useState("");
  const deleteCustomer = async (id: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/customers/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ deleteCustomer: data });
    window.location.reload();
  };
  const getCustomers = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/customers?s=${start}&l=${perPage}&q=${query}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ getCustomers: data });
    setCustomers(data.items);
    return data;
  };
  // if (!subsSet) getCustomers();
  useEffect(() => {
    getCustomers();
  }, [page, query]);

  return (
    <>
      <PageTopBreadcrumb title="Clientes IUGU" firstPath="/#/iugu-customers" />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <TextField
            type="text"
            placeholder="Pesquisar"
            helperText="Digite o e-mail ou nome do cliente para pesquisar."
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sócio</TableCell>
                <TableCell>Criada em</TableCell>
                <TableCell>Deletar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer: any) => (
                <TableRow key={customer.id}>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      title={customer.id}
                      onClick={() => {
                        // window.location.href =
                        //   "/#/customers/crud/" + customer.id + "/show";
                      }}>
                      {truncateString(customer.id, 13)}
                    </Button>
                    <br />
                    <Typography variant="caption">
                      <b>Cliente: </b>
                      {customer.name} ({customer.cpf_cnpj})
                      <br />
                      <b>E-mail: </b>
                      {customer.email}
                      <br />
                      {customer.state && (
                        <>
                          <b>Cidade/Estado: </b>{" "}
                          {customer.city + " / " + customer.state}
                          <br />
                        </>
                      )}
                      {customer.cpf_cnpj && (
                        <>
                          <b>CPF/CNPJ: </b> {customer.cpf_cnpj}
                          <br />
                        </>
                      )}
                      {customer.phone && (
                        <>
                          <b>Telefone: </b> (
                          {customer.phone_prefix + ") " + customer.phone}
                          <br />
                        </>
                      )}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {/* {customer.referenceDate} */}
                    {moment(customer.created_at).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      title="Deletar Cliente"
                      startIcon={<DeleteIcon />}
                      fullWidth
                      onClick={() => {
                        deleteCustomer(customer.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <IuguPagination setPage={setPage} page={page} list={customers} />
    </>
  );
};

export default IuguCustomersPage;
