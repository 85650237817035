import {
  List,
  DatagridConfigurable,
  TextField,
  TopToolbar,
  ExportButton,
  SingleFieldList,
  ChipField,
  ArrayField,
  FilterButton,
  CreateButton,
  TextInput,
  FunctionField,
  ReferenceField,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

const ListActions = () => (
  <TopToolbar>
    {/* <SelectColumnsButton /> */}
    <FilterButton />
    <CreateButton />
    <ExportButton />
    {/* <ImportButton defaultModel={"position-roles"} /> */}
  </TopToolbar>
);
export const UserPositionRoleList = () => {
  const postFilters = [<TextInput label="Cargo" source="name" alwaysOn />];

  return (
    <>
      <PageTopBreadcrumb
        title="Cargos e Funções"
        firstPath="/#/user-position-roles/crud"
        actionType="create"
        importButton={true}
        defaultModel="position-roles"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List filters={postFilters} actions={<ListActions />}>
                <DatagridConfigurable>
                  {/* <TextField source="id" label="ID" /> */}
                  {/*
                    { id: "DTE", name: "Divisão Técnica" },
                    { id: "CARGOS_FUNCOES", name: "Cargos e Funções" },
                    { id: "COMISSAO_EXTERNA", name: "Comissão/Externa" },
                    { id: "REPRESENTACAO_EXTERNA", name: "Bancada do CREA" },
                     */}
                  <FunctionField
                    label="Cargo"
                    render={(record: any) => {
                      return (
                        <>
                          Tipo de Cargo:{" "}
                          {record.roleType === "REPRESENTACAO_EXTERNA"
                            ? "Bancada do CREA (Representação externa)"
                            : record.roleType === "COMISSAO_EXTERNA"
                            ? "Comissão/Externa"
                            : record.roleType === "CARGOS_FUNCOES"
                            ? "Cargos e Funções"
                            : record.roleType === "DTE"
                            ? "Divisão Técnica"
                            : "--"}{" "}
                          <br />
                          Nome do Cargo: {record.name} <br />
                          Status do Cargo:{" "}
                          <ReferenceField
                            label="Status do Cargo"
                            source="roleStatusId"
                            reference="user-role-status/crud"
                            link={false}
                          >
                            <TextField source="name" />
                          </ReferenceField>
                        </>
                      );
                    }}
                  />

                  {/* <TextField source="name" label="Cargo" /> */}
                  <TextField source="yearRange" label="Período" />

                  <ArrayField label="Membros" source="users">
                    <SingleFieldList linkType={false}>
                      <ChipField source="fullName" size="small" />
                    </SingleFieldList>
                  </ArrayField>
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
