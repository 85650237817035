import moment from "moment";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { IuguPagination } from "./IuguPagination";
import { statusLabels } from "./iugu-utils";
import { truncateString } from "../../../ra-components/users/UserUtils";

const IuguMySubsPage = ({ props }: any) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [subscriptions, setSubscriptions] = useState([]);
  const deleteSubscription = async (id: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/subscriptions/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ deleteSubscription: data });
    window.location.reload();
  };
  const getSubscriptions = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;
    const responseUser = await fetch(
      `${process.env.REACT_APP_API_PATH}/users/crud/${authJson.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const dataUser = await responseUser.json();
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/subscriptions/${dataUser.iuguId}?s=${start}&l=${perPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    console.log({ getSubscriptions: data });
    setSubscriptions(data.items);
    return data;
  };
  useEffect(() => {
    getSubscriptions();
  }, [page]);
  return (
    <>
      <PageTopBreadcrumb title="Minhas Assinaturas" />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Assinatura</TableCell>
                <TableCell>Cobranças recentes</TableCell>
                <TableCell>Datas</TableCell>
              </TableRow>
            </TableHead>

            {/* {JSON.stringify(subscriptions)} */}
            <TableBody>
              {subscriptions &&
                subscriptions.length > 0 &&
                subscriptions.map((subscription: any) => (
                  <TableRow key={subscription.id}>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        title={subscription.id}
                        onClick={() => {
                          // window.location.href =
                          //   "/#/invoices/crud/" + invoice.id + "/show";
                        }}>
                        {truncateString(subscription.id, 13)}
                      </Button>
                      <br />
                      <Typography variant="caption">
                        <b>Plano: </b>
                        {subscription.plan_name || "Desconhecido"}
                        {/* <br />
                        <b>Situação da Assinatura: </b>
                        {subscription.active ? "Ativa" : "Não ativa"} */}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {subscription.recent_invoices.map((item: any) => (
                        <>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Cobrança</TableCell>
                                <TableCell>Vencimento</TableCell>
                                <TableCell>Valor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key={item.id}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {truncateString(item.id || "", 13)}
                                    <br />
                                    <b>
                                      Status:{" "}
                                      {statusLabels[item.status] || item.status}
                                    </b>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {moment(item.due_date).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant={
                                      item.status === "pending"
                                        ? "contained"
                                        : "outlined"
                                    }
                                    color={
                                      item.status === "pending"
                                        ? "success"
                                        : "info"
                                    }
                                    startIcon={<PaymentIcon />}
                                    // disabled={item.status !== "pending"}
                                    size="small"
                                    onClick={() => {
                                      window.location.href = item.secure_url;
                                    }}>
                                    Pagar{" "}
                                    {item.total
                                      .replace(" BRL", "")
                                      .replace(".", ",")}
                                  </Button>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Divider sx={{ marginTop: 1 }} />
                        </>
                      ))}
                    </TableCell>
                    <TableCell>
                      <b>Emissão:</b>{" "}
                      {moment(subscription.createdAt).format("DD-MM-YYYY")}
                      <br />
                      {/* {subscription.referenceDate} */}
                      <b>Ciclo:</b>{" "}
                      {moment(subscription.cycled_at).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <IuguPagination setPage={setPage} page={page} list={subscriptions} />
    </>
  );
};

export default IuguMySubsPage;
