
import {
  List,
  DatagridConfigurable,
  TextField,
  TextInput,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

export const TransactionCategoryList = () => {
  const postFilters = [<TextInput label="Nome" source="name" alwaysOn />];

  return (
    <>
      <PageTopBreadcrumb
        title="Categorias de Transações"
        firstPath="/#/transaction-categories/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List>
                <DatagridConfigurable>
                  {/* <TextField source="id" /> */}
                  <TextField source="name" label="Nome" />
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
