/**
 * TransactionsEdit component.
 *
 * Renders a form to edit a transaction record.
 * Uses React Admin components like SimpleForm, TextInput etc.
 * Fetches record data based on id from router params.
 * Allows selecting a member (user) via AutocompleteInput.
 * Validates and updates record via EditContextProvider.
 */
import {
  AutocompleteInput,
  EditContextProvider,
  ReferenceInput,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  SelectInput,
  useEditController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";
import { validateCompetencia } from "./transactions-util";

export const TransactionsEdit = (props: any) => {
  const { id } = useParams();
  const controller = useEditController({
    resource: "transactions/crud",
    mutationMode: "pessimistic",
    id,
  });
  const filterToQuery = (searchText: any) => ({
    mailName: `${searchText}`,
  });
  const optionRenderer = (choice: any) =>
    `${choice.fullName} ${choice.cpf ? "- CPF: " + choice.cpf : ""} 
    ${
      choice.registrationNumber
        ? " - Matrícula: " + choice.registrationNumber
        : ""
    }${
      choice.registrationNumberDigit ? "-" + choice.registrationNumberDigit : ""
    }`;

  return (
    <EditContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Transações"
          firstPath="/#/transactions/crud"
          secondTitle="Editando"
          thirdTitle={controller && controller.record && controller.record.id}
          actionType="show"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Status de Cargos e Funções" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <TextInput source="id" fullWidth helperText={false} />
                <TextInput source="id_Receita" fullWidth helperText={false} />
                <SelectInput
                  source="type"
                  choices={[
                    { id: "INCOME", name: "Receita" },
                    { id: "EXPENSE", name: "Despesa" },
                  ]}
                  fullWidth
                  helperText={false}
                />
                <TextInput
                  source="paymentMethod"
                  fullWidth
                  helperText={false}
                />
                <TextInput
                  label="Competência"
                  source="sCompetencia"
                  fullWidth
                  validate={validateCompetencia}
                  helperText={false}
                />
                <DateInput
                  label="Data Referência"
                  source="referenceDate"
                  fullWidth
                  helperText={false}
                />
                <NumberInput source="value" fullWidth helperText={false} />
                <ReferenceInput
                  source="memberId"
                  reference="users/crud"
                  perPage={10}
                  fullWidth
                  enableGetChoices={({ name }) => name && name.length >= 2}
                  alwaysOn>
                  <AutocompleteInput
                    label="Nome/E-mail/CPF"
                    optionText={optionRenderer}
                    optionValue="id"
                    fullWidth
                    filterToQuery={filterToQuery}
                  />
                </ReferenceInput>
                <TextInput
                  source="memo"
                  fullWidth
                  multiline
                  label="Observações (Memo)"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </EditContextProvider>
  );
};
