import { useState, useEffect } from "react";
import { useLogin, useNotify } from "react-admin";
import { Login } from "react-admin";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Paper,
  Card,
  Checkbox,
  TextField,
  CardHeader,
  CardContent,
  Button,
  Link,
} from "@mui/material";

const LoginPage = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [remindMe, setRemindMe] = useState(false);
  // const redirect = useRedirect();
  const login = useLogin();
  const notify = useNotify();

  // const handleClick = (location: string) => {
  //   redirect(location);
  // };

  useEffect(() => {
    // Verifica se o identificador é um CPF (apenas números)
    if (/^\d+$/.test(identifier)) {
      // Formata o CPF
      const formattedIdentifier = identifier.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      setIdentifier(formattedIdentifier);
    }
  }, [identifier]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (remindMe) {
      localStorage.setItem("username", identifier);
      localStorage.setItem("password", password);
    }

    // manual login fetch to test error response
    // console.log("pretest", process.env.REACT_APP_API_PATH);

    await fetch(`${process.env.REACT_APP_API_PATH}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ identifier, password }),
    })
      .then(async (response) => {
        const responseJson = await response.json();
        if (responseJson.message) {
          notify(responseJson.message);
        } else {
          const userProfile = await fetch(
            `${process.env.REACT_APP_API_PATH}/auth/profile`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  responseJson && responseJson.access_token
                    ? "Bearer " + responseJson.access_token
                    : "",
              },
            }
          );
          if (!userProfile.ok) throw new Error(userProfile.statusText);
          const theUser = await userProfile.json();
          const userPermissions = theUser.role.permissions;
          const redirectMap: any = {
            ADMIN_MANAGE_ANY_ANYTHING: "/users/crud",
            USER_MANAGE_OWN_CONTENT: "/users/crud/" + theUser.id + "/show",
          };
          const redirectPath = userPermissions.find(
            (permission: any) => redirectMap[permission]
          );
          login(
            {
              identifier: identifier,
              password: password,
            },
            redirectMap[redirectPath] || "/users/crud"
          );
        }
      })
      .catch((error) => {
        notify(error.message);
      });

    // login({
    //   identifier: identifier,
    //   password: password,
    // }).catch(() => {
    //   notify(
    //     localStorage.getItem("loginError") || "E-mail ou CPF ou senha inválidos"
    //   );
    // });
  };
  useEffect(() => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    if (username && password) {
      setIdentifier(username);
      setPassword(password);
      setRemindMe(true);
    }
  }, []);

  return (
    <Login
      sx={{
        padding: 3,
      }}
    >
      <Paper elevation={2}>
        <Card>
          <CardHeader
            title="Acesso Clube de Engenharia"
            subheader="Faça seu Login"
          />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container maxWidth={400} padding={1}>
                <Grid item xs={12}>
                  <TextField
                    id="identifier"
                    label="E-mail ou CPF"
                    placeholder="Informe seu e-mail ou CPF"
                    required
                    type="text"
                    value={identifier}
                    fullWidth
                    onChange={(e) => setIdentifier(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password"
                    placeholder="Informe sua senha"
                    label="Senha"
                    required
                    fullWidth
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      label="Lembrar-me"
                      control={
                        <Checkbox
                          id="remember"
                          onChange={(e) => setRemindMe(!remindMe)}
                          checked={remindMe}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Entrar
                </Button>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <br />
                  {/* <Link href={window.location.origin + "/#/register"}>
                    <b>Registrar-se</b>
                  </Link>{" "}
                  -{" "} */}
                  <Link href={window.location.origin + "/#/account-recovery"}>
                    <b>Recuperar senha</b>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          {/* <h1 className="text-2xl font-bold mb-4">Acesso Clube de Engenharia</h1> */}
        </Card>
      </Paper>
    </Login>
  );
};

export default LoginPage;
