import { AppBar, Title, TitlePortal } from "react-admin";
import { AppUserMenu } from "./AppMenus";
import { useEffect } from "react";

const textColor = "gray";
const Logo = () => (
  <img
    alt="Clube de engenharia Logo"
    src="/logo-horizontal.svg"
    style={{ width: 180, marginTop: "10px" }}
  />
);

const CustomNavbar = () => <Title title={<Logo />} />;

export function AppNavbar() {
  useEffect(() => {
    // find element with class containing "css-<RANDOMSTRING>-RaLayout-root" and remove this class from element
    // this class somehow affects scroll on tables
    if (localStorage.getItem("isMobile") === "true") {
      const layout = document.querySelector("[class*='RaLayout-root']");
      if (layout) {
        // console.log("test classList", layout.classList);
        const classIndex = Array.from(layout.classList).findIndex((c) =>
          c.startsWith("css-")
        );
        layout.classList.remove(layout.classList[classIndex]);
      }
    }
  }, []);
  return (
    <AppBar
      // place custom styles here
      sx={{
        color: textColor,
        background: "white",
        // place toolbar-custom styles here
        "& .RaAppBar-toolbar": { padding: 0, color: textColor },
        // place menu-custom styles here
        "& .RaAppBar-menuButton": { color: textColor },
        // place title-custom styles here
        "& .RaAppBar-title": { color: textColor },
      }}
      userMenu={<AppUserMenu />}>
      <CustomNavbar />
      <TitlePortal />
    </AppBar>
  );
}
