import { useEffect, useState } from "react";
import moment from "moment";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IuguPagination } from "./IuguPagination";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { truncateString } from "../../../ra-components/users/UserUtils";

const IuguPlanosPage = ({ props }: any) => {
  const [plans, setPlans] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const deletePlan = async (id: string) => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/plans/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    // console.log({ deletePlan: data });
    window.location.reload();
  };
  const getPlans = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const start = (page - 1) * perPage;

    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/plans?s=${start}&l=${perPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    console.log({ getPlans: data });
    setPlans(data.items);
    return data;
  };

  useEffect(() => {
    getPlans();
  }, [page]);

  return (
    <>
      <PageTopBreadcrumb
        title="Planos IUGU"
        firstPath="/#/iugu-planos"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plano</TableCell>
                <TableCell>Informações</TableCell>
                <TableCell>Criada em</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans &&
                plans.map((plan: any) => (
                  <TableRow key={plan.id}>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        title={plan.id}
                        onClick={() => {}}>
                        {truncateString(plan.id, 13)}
                      </Button>
                      <br />
                      <Typography variant="caption">
                        <b>Nome do Plano:</b> {plan.name}
                        <br />
                        <b>Identificador:</b> {plan.identifier}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {plan.prices.length > 0 && (
                        <Typography variant="body2">
                          <b>Preço(s):</b>
                          <br />
                          {plan.prices.map((price: any) => {
                            return (
                              <>
                                R${" "}
                                {(parseInt(price.value_cents) / 100)
                                  .toFixed(2)
                                  .replace(".", ",")}{" "}
                              </>
                            );
                          })}
                        </Typography>
                      )}
                      {plan.interval && (
                        <Typography variant="body2">
                          <b>Intervalo:</b>{" "}
                          {plan.interval + " " + plan.interval_type}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(plan.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        title="Deletar plano"
                        startIcon={<DeleteIcon />}
                        fullWidth
                        onClick={() => {
                          deletePlan(plan.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <IuguPagination setPage={setPage} page={page} list={plans} />
    </>
  );
};

export default IuguPlanosPage;
