
import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useCreateController,
  BooleanInput,
  NumberInput,
} from "react-admin";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

export const UserElectionCreate = (props: any) => {
  const controller: any = useCreateController({
    resource: "user-elections/crud",
  });
  return (
    <CreateContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Eleições"
          firstPath="/#/user-elections/crud"
          secondTitle="Novo"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader
            title="Criar Novo"
            // subheader="September 14, 2016"
          />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12} lg={12}>
                <TextInput
                  source="name"
                  label="Eleição"
                  fullWidth
                  helperText={false}
                />
                <BooleanInput
                  source="participated"
                  label="Participou na eleição"
                  fullWidth
                  helperText={false}
                />
                <NumberInput
                  source="year"
                  label="Ano"
                  fullWidth
                  helperText={false}
                />
                <ReferenceInput source="userId" reference="users/crud">
                  <AutocompleteInput
                    label="Sócio"
                    optionText="fullName"
                    optionValue="id"
                    fullWidth
                    helperText={false}
                    filterToQuery={(searchText: string) => ({
                      mailName: searchText,
                    })}
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </CreateContextProvider>
  );
};
