import {
  List,
  TextField,
  TextInput,
  DatagridConfigurable,
  DateField,
  ReferenceField,
  ExportButton,
  ReferenceInput,
  FunctionField,
  SelectInput,
  AutocompleteInput,
  ChipField,
  TopToolbar,
  FilterButton,
  DateInput,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { FaEye } from "react-icons/fa";
export const TransactionsList = () => {
  const filterToQuery = (searchText: any) => ({
    mailName: `${searchText}`,
  });
  const filterToQueryTransactions = (searchText: any) => ({
    id: `${searchText}`,
  });
  const optionRenderer = (choice: any) =>
    `${choice.fullName} ${choice.cpf ? "- CPF: " + choice.cpf : ""} 
    ${
      choice.registrationNumber
        ? " - Matrícula: " + choice.registrationNumber
        : ""
    }${
      choice.registrationNumberDigit ? "-" + choice.registrationNumberDigit : ""
    }`;

  const ListActions = () => (
    <TopToolbar>
      {/* <SelectColumnsButton /> */}
      <FilterButton />
      {/* <CreateButton /> */}
      <ExportButton />
      {/* <ImportButton defaultModel={"elections"} /> */}
    </TopToolbar>
  );

  const postFilters = [
    <ReferenceInput
      source="memberId"
      reference="users/crud"
      fullWidth
      enableGetChoices={({ name }) => name && name.length >= 3}
      alwaysOn
    >
      <AutocompleteInput
        label="Nome/E-mail/CPF"
        optionText={optionRenderer}
        optionValue="id"
        fullWidth
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>,
    <DateInput source="referenceDate" label="Data Referência" />,
    // <TextInput label="Nome" source="name" alwaysOn />,
    <ReferenceInput
      reference="orphaned-users/crud"
      source="orphanedMemberId"
      label="ID Membro Órfão"
      perPage={3000}
    >
      <AutocompleteInput
        label="ID Membro Órfão"
        optionText="sNome"
        optionValue="id"
        filterToQuery={filterToQueryTransactions}
        suggestionLimit={50}
      />
    </ReferenceInput>,
    <TextInput label="Competência" source="sCompetencia" />,
    <TextInput label="Forma de Pgto." source="paymentMethod" />,
    <ReferenceInput
      label="Categoria"
      source="id_Receita"
      reference="transaction-categories/crud"
    >
      <SelectInput label="Categoria" optionText="name" />
    </ReferenceInput>,
    <SelectInput
      label="Transações órfãs"
      source="hasMemberId"
      choices={[
        { id: "true", name: "Não" },
        { id: "false", name: "Sim" },
      ]}
    />,
  ];

  return (
    <>
      <PageTopBreadcrumb
        title="Transações"
        firstPath="/#/transactions/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <List
                filters={postFilters}
                actions={<ListActions />}
                perPage={25}
              >
                <DatagridConfigurable>
                  {/* <TextField label="Nº" source="id" /> */}
                  {/* <FunctionField
                    label="UID"
                    render={(record: any) =>
                      record.memberId ? <>{record.memberId}</> : <></>
                    }
                  /> */}
                  <FunctionField
                    label="Nome/Membro"
                    render={(record: any) =>
                      record.memberId ? (
                        <ReferenceField
                          source="memberId"
                          reference="users/crud"
                          label="Sócio"
                          link="show"
                        >
                          <ChipField source="fullName" size="small" />
                        </ReferenceField>
                      ) : (
                        <>
                          <FunctionField
                            label="Membro Órfão"
                            render={(record: any) =>
                              !record.orphanedMemberId ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    variant="outlined"
                                    title={
                                      "Membro órfão: " + record.orphanedMemberId
                                    }
                                    size="small"
                                    startIcon={<FaEye />}
                                    onClick={() => {
                                      window.location.href = `/#/orphaned-users/crud/${record.orphanedMemberId}/show`;
                                    }}
                                  >
                                    M. Órfão
                                  </Button>
                                </>
                              )
                            }
                          />
                        </>
                      )
                    }
                  />
                  <ReferenceField
                    source="id_Receita"
                    reference="transaction-categories/crud"
                    label="Categoria"
                    link="show"
                  >
                    <ChipField source="name" size="small" />
                  </ReferenceField>

                  <DateField
                    label="Data Referência"
                    source="referenceDate"
                    locales="pt-BR"
                    fontSize={13}
                  />
                  <FunctionField
                    label="Valor"
                    fontSize={13}
                    render={(record: any) => (
                      <>R$ {parseFloat(record.value).toFixed(2)}</>
                    )}
                  />

                  <TextField
                    fontSize={13}
                    label="Forma de pagamento"
                    source="paymentMethod"
                  />
                  <TextField
                    label="Competência"
                    source="sCompetencia"
                    fontSize={13}
                  />
                  <ActionsDropdown />
                </DatagridConfigurable>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
