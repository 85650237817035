import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  Labeled,
  useEditController,
  ReferenceField,
  BooleanField,
} from "react-admin";
import { useParams } from "react-router-dom";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

export const UserElectionShow = () => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "user-elections/crud",
    id,
  });
  return (
    <ShowContextProvider value={controller}>
      <SimpleShowLayout className="RaShowClass">
        <PageTopBreadcrumb
          title="Eleições"
          firstPath="/#/user-elections/crud"
          secondTitle="Visualizando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="edit"
        />
        <Card sx={{ padding: 2, marginBottom: 3 }}>
          <CardHeader title="Eleções" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Stack>
                  <Labeled>
                    <TextField source="id" />
                  </Labeled>
                  <Labeled>
                    <TextField source="name" label="Eleição" />
                  </Labeled>
                  <Labeled>
                    <BooleanField source="participated" label="Participou" />
                  </Labeled>
                  <Labeled>
                    <TextField source="year" label="Ano" />
                  </Labeled>
                  <Labeled>
                    <ReferenceField
                      source="userId"
                      reference="users/crud"
                      label="Membro"
                    >
                      <TextField source="fullName" size="small" />
                    </ReferenceField>
                  </Labeled>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleShowLayout>
    </ShowContextProvider>
  );
};
