import { JsonField } from "react-admin-json-view";
import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  Labeled,
  useEditController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";

export const UserRolesShow = () => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "user-roles/crud",
    id,
  });
  return (
    <ShowContextProvider value={controller}>
      <SimpleShowLayout className="RaShowClass">
        <PageTopBreadcrumb
          title="Regras de Usuários"
          firstPath="/#/user-roles/crud"
          secondTitle="Visualizando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="edit"
        />
        <Card sx={{ padding: 2, marginBottom: 3 }}>
          <CardHeader title="Regra de Usuários" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Stack>
                  <Labeled>
                    <TextField source="id" label="ID" fullWidth />
                  </Labeled>
                  <Labeled>
                    <TextField source="name" label="Nome" fullWidth />
                  </Labeled>
                  <Labeled>
                    <JsonField
                      source="permissions"
                      jsonString={false}
                      fullWidth
                      label="Permissões"
                      reactJsonOptions={{
                        name: null,
                        collapsed: true,
                        enableClipboard: false,
                        displayDataTypes: false,
                      }}
                    />
                  </Labeled>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleShowLayout>
    </ShowContextProvider>
  );
};
