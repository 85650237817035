import React from "react";
import { useCreate, useCreateSuggestionContext } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

export const CreateEducationalInstitution = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
      "educational-institutions/crud",
      { data: { name: value } },
      {
        onSuccess: (data) => {
          setValue("");
          onCreate(data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="Nome da instituição de ensino"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Salvar</Button>
          <Button onClick={onCancel}>Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
