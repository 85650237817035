import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  BooleanInput,
  useCreateController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";

export const EducationalInstitutionCreate = (props: any) => {
  const controller: any = useCreateController({
    resource: "educational-institutions/crud",
  });

  return (
    <CreateContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Instituição de Ensino"
          firstPath="/#/educational-institutions/crud"
          secondTitle="Novo"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Criar" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12} lg={12}>
                <TextInput source="name" fullWidth label="Nome" />
                <BooleanInput source="reviewed" label="Revisado" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </CreateContextProvider>
  );
};
