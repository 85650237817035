import {
  Create,
  CreateContextProvider,
  SimpleForm,
  TextInput,
  useCreate,
  useCreateController,
  SelectInput,
  ReferenceInput,
  DateInput,
  NumberInput,
  AutocompleteInput,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useNavigate } from "react-router-dom";
import { validateCompetencia } from "./transactions-util";

export const TransactionsCreate = (props: any) => {
  const controller: any = useCreateController({
    resource: "transactions/crud",
  });
  const history = useNavigate();

  const handleCreateSuccess: any = () => {
    history("/#/transactions/crud");
  };

  const handleCreate = useCreate(
    "transactions/crud",
    {
      data: controller.record,
    },
    {
      onSuccess: handleCreateSuccess,
      onError: (error) => {
        console.error("Error: ", error);
      },
    }
  );

  const filterToQuery = (searchText: any) => ({
    mailName: `${searchText}`,
  });
  const optionRenderer = (choice: any) =>
    `${choice.fullName} ${choice.cpf ? "- CPF: " + choice.cpf : ""} 
    ${
      choice.registrationNumber
        ? " - Matrícula: " + choice.registrationNumber
        : ""
    }${
      choice.registrationNumberDigit ? "-" + choice.registrationNumberDigit : ""
    }`;

  return (
    <CreateContextProvider value={controller}>
      {/* <Create
        redirect="list"
        sx={
          // remove background color
          { backgroundColor: "transparent" }
        }
      > */}
      <PageTopBreadcrumb
        title="Transações"
        firstPath="/#/transactions/crud"
        secondTitle="Novo"
      />
      <Create
        redirect="list"
        sx={{
          width: "100%",
          marginBottom: 3,
        }}
      >
        <SimpleForm>
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Criar" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12} lg={12}>
                  <SelectInput
                    source="type"
                    label="Tipo"
                    choices={[
                      { id: "INCOME", name: "Receita" },
                      { id: "EXPENSE", name: "Despesa" },
                    ]}
                    defaultValue={"INCOME"}
                    fullWidth
                    helperText={false}
                  />
                  <TextInput
                    label="Método de Pagamento"
                    source="paymentMethod"
                    fullWidth
                    helperText={false}
                  />
                  <TextInput
                    label="Competência"
                    source="sCompetencia"
                    fullWidth
                    validate={validateCompetencia}
                    helperText={false}
                  />
                  <DateInput
                    label="Data Referência"
                    source="referenceDate"
                    fullWidth
                    helperText={false}
                  />
                  <NumberInput
                    source="value"
                    label="Valor"
                    fullWidth
                    helperText={"Use pontos para decimais"}
                  />
                  {/* <TextInput source="id_Receita" fullWidth helperText={false} /> */}
                  <ReferenceInput
                    source="id_Receita"
                    reference="transaction-categories/crud"
                  >
                    <AutocompleteInput
                      label="Categoria"
                      optionText="name"
                      optionValue="id"
                      fullWidth
                      helperText={false}
                    />
                  </ReferenceInput>
                  {/* <ReferenceInput source="memberId" reference="users/crud">
                  <AutocompleteInput
                    label="Sócio"
                    optionText="fullName"
                    optionValue="id"
                    fullWidth
                    helperText={false}
                  />
                </ReferenceInput> */}
                  <ReferenceInput
                    source="memberId"
                    reference="users/crud"
                    perPage={10}
                    fullWidth
                    enableGetChoices={({ name }) => name && name.length >= 2}
                    alwaysOn
                  >
                    <AutocompleteInput
                      label="Nome/E-mail/CPF"
                      optionText={optionRenderer}
                      optionValue="id"
                      fullWidth
                      filterToQuery={filterToQuery}
                    />
                  </ReferenceInput>
                  <TextInput
                    source="memo"
                    fullWidth
                    multiline
                    label="Observações (Memo)"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleForm>
      </Create>
      {/* </Create> */}
    </CreateContextProvider>
  );
};
