import * as React from "react";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Report";
import History from "@mui/icons-material/History";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

type AppErrorProps = {
  title: string;
  message: string;
  severity?: "error" | "warning" | "info" | "success";
};

export const AppError = (props: AppErrorProps) => {
  const { title, message, severity } = props;

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity={severity || "error"}>
        {title} — {message}
      </Alert>
    </Stack>
  );
};
