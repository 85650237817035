import {
  WrapperField,
  FunctionField,
  TextField,
  BooleanField,
} from "react-admin";
import { truncateString } from "./UserUtils";
import { Button, Chip, Tooltip, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export const UserListSocioColumn = () => {
  return (
    <FunctionField
      label="Dados do Sócio"
      render={(record: any) => {
        return (
          <Box
            className="rowUserBlock"
            onClick={() => {
              // console.log("click");
              window.location.href = `/#/users/crud/${record.id}/show`;
            }}>
            {record.fullName && (
              <>
                <TextField
                  label="Nome Completo"
                  source="fullName"
                  fontSize={12}
                />
                <Divider />
              </>
            )}
            {record.email && (
              <>
                <Typography fontSize={12} title={record.email}>
                  {truncateString(record.email, 23)}
                </Typography>
                <Divider />
              </>
            )}
            {record.cpf && (
              <>
                <Typography fontSize={12}>
                  CPF: <TextField source="cpf" fontSize={12} />
                </Typography>
                <Divider />
              </>
            )}
            {record.CREA && (
              <>
                <Typography fontSize={12}>
                  CREA: <TextField source="CREA" fontSize={12} />
                </Typography>
                <Divider />
              </>
            )}
            {record.professionalActivity && (
              <>
                <Typography fontSize={12}>
                  <TextField
                    source="professionalActivity"
                    size="small"
                    fontSize={12}
                  />
                </Typography>
                <Divider />
              </>
            )}
            {/* {record.gender && (
                                        <div className="text-xs text-gray-600">
                                          Sexo:{" "}
                                          <FunctionField
                                            label="Sexo"
                                            render={(record: any) => {
                                              return record.gender === "M"
                                                ? "Masculino"
                                                : record.gender === "F"
                                                ? "Feminino"
                                                : "N/A";
                                            }}
                                          />
                                        </div>
                                      )} */}
            {/* {lazyComplianceCheck(record)} */}

            {record.maritalStatus && (
              <>
                <Typography fontSize={12}>
                  Estado Civil:{" "}
                  <FunctionField
                    fontSize={12}
                    label="Estado Civil"
                    render={(record: any) => {
                      return record.maritalStatus &&
                        record.maritalStatus !== "UNIAO_ESTAVEL"
                        ? record.maritalStatus.charAt(0).toUpperCase() +
                            record.maritalStatus.toLowerCase().slice(1) +
                            "(a)"
                        : record.maritalStatus === "UNIAO_ESTAVEL"
                        ? "União Estável"
                        : "";
                    }}
                  />
                </Typography>
                <Divider />
              </>
            )}
            {record.phoneNumber && (
              <>
                <Typography fontSize={12}>
                  Celular: <TextField source="phoneNumber" fontSize={12} />
                </Typography>
                <Divider />
              </>
            )}
            {/* {record.address && (
              <>
                <Typography fontSize={12}>
                  Endereço: <TextField source="address" fontSize={12} />
                  City: <TextField source="addressCity" fontSize={12} />
                  State: <TextField source="addressState" fontSize={12} />
                  commercialAddress:{" "}
                  <TextField source="commercialAddress" fontSize={12} />
                </Typography>
                <Divider />
              </>
            )} */}
            <Divider />
            <Typography fontSize={12}>
              Carteirinha:{" "}
              <BooleanField source="hasPhysicalCard" label="Carteirinha" />
            </Typography>
            <Divider />
          </Box>
        );
      }}
    />
  );
};
