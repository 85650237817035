import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  ReferenceInput,
  useCreateController,
  AutocompleteInput,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
} from "react-admin";
import { useState } from "react";

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";

export const UserPositionRoleCreate = (props: any) => {
  const [roleType, setRoleType] = useState("");

  const controller: any = useCreateController({
    resource: "user-position-roles/crud",
  });

  const changeRoleType = (e: any) => {
    setRoleType(e.target.value);
  };

  return (
    <CreateContextProvider value={controller}>
      <SimpleForm>
        <PageTopBreadcrumb
          title="Cargos e Funções"
          firstPath="/#/user-position-roles/crud"
          secondTitle="Novo"
        />
        <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
          <CardHeader title="Criar" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12} lg={12}>
                <SelectInput
                  source="roleType"
                  label="Tipo de Cargo"
                  fullWidth
                  required
                  helperText={
                    "Selecione um tipo de cargo para criar (DTE, CARGOS_FUNCOES, COMISSAO_EXTERNA)"
                  }
                  choices={[
                    { id: "DTE", name: "Divisão Técnica" },
                    { id: "CARGOS_FUNCOES", name: "Cargos e Funções" },
                    { id: "COMISSAO_EXTERNA", name: "Comissão/Externa" },
                    { id: "REPRESENTACAO_EXTERNA", name: "Bancada do CREA" },
                  ]}
                  onChange={(e) => {
                    changeRoleType(e);
                  }}
                />
                <TextInput
                  source="name"
                  label={
                    "Nome " +
                    (roleType === "COMISSAO_EXTERNA"
                      ? "da Comissão"
                      : "do Cargo")
                  }
                  required
                  fullWidth
                  helperText={
                    roleType === "DTE"
                      ? "(Chefe, Subchefe, Secretário...)"
                      : roleType === "CARGOS_FUNCOES"
                      ? "(Presidente, Diretor, Conselheiro(a)...)"
                      : roleType === "COMISSAO_EXTERNA"
                      ? "Comissão XYZ"
                      : false
                  }
                />
                {roleType === "COMISSAO_EXTERNA" && (
                  <>
                    <TextInput
                      source="secondaryRole"
                      label="Cargo (comissões externas)"
                      fullWidth
                      helperText={"Ex.: Titular"}
                    />
                    <SelectInput
                      source="sphere"
                      label="Esfera de Atuação"
                      fullWidth
                      required
                      helperText={"Ex.: Federal, Estadual, Municipal..."}
                      choices={[
                        { id: "federal", name: "Federal" },
                        { id: "estadual", name: "Estadual" },
                        { id: "municipal", name: "Municipal" },
                        { id: "civil", name: "Civil" },
                      ]}
                    />
                  </>
                )}

                <ReferenceInput
                  source="roleStatusId"
                  reference="user-role-status/crud"
                  // sort={{ field: "reviewed", order: "DESC" }}
                  perPage={50}
                  // enableGetChoices={({ name }) => name && name.length >= 2}
                >
                  <AutocompleteInput
                    label="Status do Cargo"
                    optionText="name"
                    optionValue="id"
                    isRequired
                    // sort={{ field: 'name', order: 'ASC' }}
                    // filterToQuery={filterToQuery}
                    sx={{ minWidth: "50vw" }}
                  />
                </ReferenceInput>

                {roleType === "DTE" && (
                  <ReferenceInput
                    source="technicalDivisionId"
                    reference="technical-divisions/crud"

                    // perPage={10}
                    // enableGetChoices={({ name }) => name && name.length >= 2}
                  >
                    <AutocompleteInput
                      label="Divisão Técnica"
                      optionText="name"
                      optionValue="id"
                      fullWidth
                      helperText={false}
                    />
                  </ReferenceInput>
                )}
                {roleType !== "COMISSAO_EXTERNA" && (
                  <TextInput
                    source="yearRange"
                    label="Período"
                    fullWidth
                    helperText={"Ex.: 2006/2012"}
                  />
                )}

                <DateInput
                  source="fromDate"
                  label="A partir de (data)"
                  helperText={false}
                />
                <DateInput
                  source="toDate"
                  label="Até (data)"
                  helperText={false}
                />

                <ReferenceArrayInput reference="users/crud" source="users">
                  <AutocompleteArrayInput
                    optionText="fullName"
                    optionValue="id"
                    label="Sócios"
                    filterToQuery={(searchText: string) => ({
                      mailName: searchText,
                    })}
                    helperText={
                      "Consulte pelo / nome / mail / cpf. É possível selecionar múltiplos."
                    }

                    // create={<TechDivisionCreate showListActions={false} />}
                  />
                  {/* //TODO CREATION NOT WORKING */}
                  {/* //TODO NOT SELECTING EXISTING */}
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </CreateContextProvider>
  );
};
