import portugueseMessages from "ra-language-pt-br";

export const alterPortuguese = {
  ...portugueseMessages,
  ra: {
    ...portugueseMessages.ra,
    message: {
      ...portugueseMessages.ra.message,
      clear_array_input: "Tem certeza que deseja limpar tudo?",
    },
    action: {
      ...portugueseMessages.ra.action,
      select_columns: "Colunas",
      remove_all_filters: "Remover todos os filtros",
      save_current_query: "Salvar consulta atual",
      clear_array_input: "Limpar tudo",
      create_item: "Criar novo registro",
      move_up: "Mover para cima",
      move_down: "Mover para baixo",
    },
    configurable: {
      //   ...portugueseMessages.ra.configurable,
      customize: "Customizar",
    },
    saved_queries: {
      new_label: "Salvar consulta atual...",
      help: "Filtrar a lista e salvar essa consulta para mais tarde",
      label: "Consultas salvas",
      new_dialog_title: "Salvar consulta atual como",
      query_name: "Nome da consulta",
      remove_dialog_title: "Remover consulta salva?",
      remove_label: "Remover consulta salva",
      remove_label_with_name: 'Remover consulta "%{name}"',
      remove_message:
        "Tem certeza de que deseja remover esse item da sua lista de consultas salvas?",
    },
  },
};
