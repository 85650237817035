import PieChart from "../dashboard/PieChart";
import { PageTopBreadcrumb } from "../PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

export const DashboardPage = ({ props }: any) => {
  return (
    <>
      <PageTopBreadcrumb title="Dashboard" firstPath="/#/dashboard" />
      {/* {JSON.stringify(localStorage.getItem("auth"))} */}

      {/* <TextInputMUI
        type="text"
        value={token}
        onChange={(e) => {
          setToken(e.target.value);
          localStorage.setItem(
            "auth",
            JSON.stringify({ access_token: e.target.value })
          );
        }}
      /> */}
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <PieChart props={props} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
