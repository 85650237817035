export const validateCompetencia = (value: any) => {
  if (!value) return "Campo obrigatório";
  const currentYear = new Date().getFullYear();
  const isValidYear = (year: string) => {
    const parsedYear = parseInt(year);
    return (
      !isNaN(parsedYear) && parsedYear >= 1900 && parsedYear <= currentYear
    );
  };

  if (
    value !== "VITAL" &&
    value !== "PENDENTE" &&
    !value.match(/^(\d{4})(\.[12])?$/) &&
    !isValidYear(value)
  ) {
    return "Formato inválido. Utilize: 'VITAL', 'PENDENTE',  'AAAA' ou 'AAAA.S'";
  }
  // if it has semester, but semester is empty
  if (value.split(".")[1] && value.split(".")[1] === "") {
    return "Semestre não informado. Utilize '1' ou '2'.";
  }
  const semesterIsNumber = value.split(".")[1] && !isNaN(value.split(".")[1]);
  if (value.match(/^\d{4}\.(1|2)$/)) {
    return undefined;
  } else if (value.match(/^\d{4}\.\d{1,2}$/)) {
    return "Formato inválido. O semestre deve ser '1' ou '2'.";
  }
  if (value.split(".")[1] && !semesterIsNumber) {
    return "Formato inválido. O semestre deve ser '1' ou '2'.";
  }
  return undefined;
};
