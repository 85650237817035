import { DateTime } from "luxon";

function formatDateTime(dateString: string) {
  if (!dateString) return null;
  const dateRegexes = [
    /^\d{2}\/\d{2}\/\d{4}$/, // Formato: dd/mm/yyyy
    /^\d{8}$/, // Formato: ddmmyyyy
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}$/, // Formato: yyyy-mm-dd HH:mm:ss.SSS
    // Adicione outras expressões regulares para formatos diferentes, se necessário
  ];

  for (const regex of dateRegexes) {
    if (regex.test(dateString)) {
      let day: any, month: any, year: any;
      if (regex.source === /^\d{2}\/\d{2}\/\d{4}$/.source) {
        [day, month, year] = dateString.split("/");
      } else if (regex.source === /^\d{8}$/.source) {
        day = dateString.substr(0, 2);
        month = dateString.substr(2, 2);
        year = dateString.substr(4, 4);
      } else if (
        regex.source === /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}$/.source
      ) {
        const dateTimeParts = dateString.split(" ");
        const dateParts = dateTimeParts[0].split("-");
        const timeParts = dateTimeParts[1].split(":");

        [year, month, day] = dateParts;
        const [hour, minute, second] = timeParts;

        return DateTime.fromObject({
          year: parseInt(year),
          month: parseInt(month),
          day: parseInt(day),
          hour: parseInt(hour),
          minute: parseInt(minute),
          second: parseInt(second),
        }).toJSDate();
      }

      const formattedDay = day.padStart(2, "0");
      const formattedMonth = month.padStart(2, "0");
      return DateTime.fromFormat(
        `${formattedDay}/${formattedMonth}/${year}`,
        "dd/MM/yyyy"
      ).toJSDate();
    }
  }
}

/**
 *
 * @param role
 * @returns finance | comms | admin | user | secretary | unknown
 */
export function determineUserReadableRole(roles: any) {
  if (roles?.includes("ADMIN_MANAGE_ANY_ANYTHING")) {
    // console.log("userRoleProfile", "admin");
    return "admin";
  }
  if (roles?.includes("USER_MANAGE_FINANCE")) {
    // console.log("userRoleProfile", "finance");
    return "finance";
  }
  if (roles?.includes("USER_VIEW_BASIC_CONTENT")) {
    // console.log("userRoleProfile", "comms");

    return "comms";
  }
  if (roles?.includes("USER_VIEW_FULL_CONTENT")) {
    // console.log("userRoleProfile", "comms");

    return "secretary";
  }
  if (roles?.includes("USER_MANAGE_OWN_CONTENT")) {
    // console.log("userRoleProfile", "user");

    return "user";
  }
  // console.log("userRoleProfile", "unknown");

  return "unknown";
}

function formatDateFromExcelValue(excelValue: any) {
  if (isNaN(excelValue) || excelValue == null || !excelValue) {
    return null;
  }
  // Convert Excel serial date to JavaScript date
  const options = {
    year: "numeric" as const,
    month: "2-digit" as const,
    day: "2-digit" as const,
  };
  const excelEpoch = new Date(Date.UTC(1900, 0, 0));
  const jsDate = new Date(
    excelEpoch.getTime() + excelValue * 24 * 60 * 60 * 1000
  );

  return jsDate.toLocaleDateString("pt-BR", options);
}

export function parseDateTime(dateString: any) {
  const excelFormat = formatDateFromExcelValue(dateString);
  if (!excelFormat || excelFormat == null) {
    return formatDateTime(dateString);
  }
  return formatDateTime(excelFormat);
}
export function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
}

export const assignCustomer = async (
  iuguId: string,
  userId: string,
  reload?: boolean
) => {
  const auth = localStorage.getItem("auth");
  const authJson = auth ? JSON.parse(auth) : auth;
  // console.log("fetch assign customer", iuguId, userId);
  const response = await fetch(
    `${process.env.REACT_APP_API_PATH}/iugu/customers/assign/${iuguId}/${userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authJson.access_token,
      },
    }
  );
  const data = await response.json();
  if (data.id && reload) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  // console.log("fetch assign customer data", data);
  if (data.error) {
    // setAssignMessage(data.error);
  }
};

// make noise white noise ground noise
