import {
  List,
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  DateField,
} from "react-admin";
import ActionsDropdown from "../../components/ActionsDropdown";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { FaEye } from "react-icons/fa";

const typeLabels: any = {
  other: "Outro",
  request: "Solicitação de Alteração",
  bug: "Reportar Bug",
  feature: "Solicitar Recurso",
  debit: "Débito",
  contact: "Contato",
};
export const FeedbacksList = () => {
  const postFilters = [<TextInput label="E-mail" source="email" alwaysOn />];
  // const userRole = localStorage.getItem("roles");
  // const redirect = useRedirect();
  // console.log("test role", determineUserReadableRole(userRole));
  // if (determineUserReadableRole(userRole) === "user") {
  //   redirect("/feedbacks/crud/create");
  // }

  return (
    <>
      <PageTopBreadcrumb
        title="Contatos"
        firstPath="/#/feedbacks/crud"
        actionType="create"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              {/* {determineUserReadableRole(userRole) === "admin" && ( */}
              <List filters={postFilters}>
                <Datagrid>
                  {/* <TextField source="id" /> */}
                  <FunctionField
                    label="Membro"
                    render={(record: any) => {
                      return (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            window.location.href =
                              "/#/users/crud/?filter=%7B%22mailName%22%3A%22" +
                              record.email +
                              "%22%7D";
                          }}
                          fullWidth
                          startIcon={<FaEye />}
                        >
                          {record.email}
                        </Button>
                      );
                    }}
                  />
                  <TextField source="message" label="Mensagem" />
                  <FunctionField
                    label="Tipo de Contato"
                    render={(record: any) => {
                      return typeLabels[record.type] || record.type;
                    }}
                  />
                  <DateField
                    source="createdAt"
                    locales="pt-BR"
                    label="Data de Criação"
                  />

                  <ActionsDropdown />
                </Datagrid>
              </List>
              {/* )} */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
