import React from "react";
import { DeleteButton, EditButton, ShowButton } from "react-admin";
import { HiDotsVertical } from "react-icons/hi";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { determineUserReadableRole } from "../ra-components/users/UserUtils";

const DropdownMenu = () => {
  const userRole = localStorage.getItem("roles");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <div>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <HiDotsVertical />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="right-end"
            transition
            disablePortal
            sx={{ zIndex: 2, width: 110, alignContent: "center" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper className="actionMenu">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <ShowButton label="Mostrar" />
                      {determineUserReadableRole(userRole) === "admin" && (
                        <>
                          <EditButton label="Editar" />
                          <DeleteButton label="Excluir" />
                        </>
                      )}

                      {/* <MenuItem onClick={handleClose}>
                      </MenuItem> */}
                      {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                      <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
      {/* <Dropdown
        label={<HiDotsVertical />}
        theme={customTheme}
        style={{
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Dropdown.Item>
          <ShowButton label="Mostrar" />
        </Dropdown.Item>
        <Dropdown.Item>
          <EditButton label="Editar" />
        </Dropdown.Item>
        <Dropdown.Item>
          <DeleteButton label="Excluir" />
        </Dropdown.Item>
      </Dropdown> */}
    </>
  );
};

export default DropdownMenu;
