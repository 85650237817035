import {
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  ChipField,
  Labeled,
  useEditController,
  SingleFieldList,
  DateField,
  ReferenceArrayField,
  FunctionField,
  ReferenceField,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import { useParams } from "react-router-dom";
export const UserPositionRoleShow = () => {
  const { id } = useParams();
  const controller: any = useEditController({
    resource: "user-position-roles/crud",
    id,
  });

  return (
    <ShowContextProvider value={controller}>
      <SimpleShowLayout className="RaShowClass">
        <PageTopBreadcrumb
          title="Cargos e Funções"
          firstPath="/#/user-position-roles/crud"
          secondTitle="Visualizando"
          thirdTitle={controller && controller.record && controller.record.name}
          actionType="edit"
        />
        <Card sx={{ padding: 2, marginBottom: 3 }}>
          <CardHeader title="Cargos e Funções" />
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Stack>
                  <Labeled>
                    <TextField source="id" label="ID" />
                  </Labeled>
                  <Labeled>
                    <TextField source="roleType" label="Tipo de Cargo" />
                  </Labeled>
                  <Labeled>
                    <TextField source="name" label="Nome" />
                  </Labeled>
                  <Labeled>
                    <ReferenceField
                      source="roleStatusId"
                      label="Status"
                      reference="user-role-status/crud"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                  <FunctionField
                    render={(record: any) =>
                      record.secondaryRole ? (
                        <Labeled>
                          <TextField source="secondaryRole" label="Cargo" />
                        </Labeled>
                      ) : (
                        <></>
                      )
                    }
                  />
                  <FunctionField
                    render={(record: any) =>
                      record.sphere ? (
                        <Labeled>
                          <TextField source="sphere" label="Esfera" />
                        </Labeled>
                      ) : (
                        <></>
                      )
                    }
                  />
                  <FunctionField
                    render={(record: any) =>
                      record.secondaryRole ? (
                        <Labeled>
                          <TextField source="yearRange" label="Período" />
                        </Labeled>
                      ) : (
                        <></>
                      )
                    }
                  />
                  <FunctionField
                    render={(record: any) =>
                      record.secondaryRole ? (
                        <Labeled>
                          <DateField
                            source="fromDate"
                            label="Data início"
                            locales="pt-BR"
                          />
                        </Labeled>
                      ) : (
                        <></>
                      )
                    }
                  />
                  <FunctionField
                    render={(record: any) =>
                      record.secondaryRole ? (
                        <Labeled>
                          <DateField
                            source="toDate"
                            label="Data fim"
                            locales="pt-BR"
                          />
                        </Labeled>
                      ) : (
                        <></>
                      )
                    }
                  />

                  <Labeled>
                    <ReferenceArrayField
                      label="Membros"
                      reference="users/crud"
                      source="users"
                    >
                      <SingleFieldList linkType={false}>
                        <ChipField source="fullName" size="small" />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  </Labeled>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleShowLayout>
    </ShowContextProvider>
  );
};
