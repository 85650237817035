import { defaultTheme } from "react-admin";
export const theme = {
  palette: {
    mode: "light",
    primary: {
      main: "#394382",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#394382",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: "Raleway, Arial",
  },
  components: {
    ...defaultTheme.components,
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: "#394382",
        },
      },
    },
  },
};

export const themeClean = {
  ...defaultTheme,
  sidebar: {
    width: 320, // The default value is 240
    closedWidth: 5, // The default value is 55
  },
  // ptBR,
};
