import React, { useEffect, useState } from "react";
import {
  Form,
  // DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { PageTopBreadcrumb } from "../../PageTopBreadcrumb";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const CreateSubscriptionPage = (props: any) => {
  const queryUrl = window.location.href;
  // extract uid from url
  let uid = queryUrl.split("/").pop();
  uid = uid ? uid.split("=").pop() : "";

  // let preSelectUser = urlParams["*"];
  if (uid === "create") {
    uid = "";
  }

  const notify = useNotify();
  const payableOptions = ["pix", "credit_card", "bank_slip"];
  const [plans, setPlans] = useState([] as any);
  const [formValues, setFormValues] = useState({} as any);
  const [userIuguID, setUserIuguID] = useState("");
  const dataProvider = useDataProvider();
  const [payableOption, setPayableOption] = React.useState<string[]>([]);

  const filterToQuery = (searchText: any) => ({
    mailName: `${searchText}`,
  });
  const optionRenderer = (choice: any) =>
    `${choice.fullName} ${choice.cpf ? "- CPF: " + choice.cpf : ""} 
    ${
      choice.registrationNumber
        ? " - Matrícula: " + choice.registrationNumber
        : ""
    }${
      choice.registrationNumberDigit ? "-" + choice.registrationNumberDigit : ""
    }`;

  const getUserIuguID = async (uid: string) => {
    try {
      const { data } = await dataProvider.getOne("users/crud", {
        id: uid,
      });

      const customer_id = data.iuguId;
      setUserIuguID(data.iuguId);
      // if no email
      if (!formValues.customer_id || formValues.customer_id !== data.iuguId) {
        setFormValues({
          ...formValues,
          customer_id,
        });
      }
      // setFormValues({ ...formValues, email: userEmail });
    } catch (error) {
      console.error("getUserIuguID error", error);
    }
    // setFormValues({ ...formValues, userId: data });
  };
  const handleSetUserIuguID = async (userId: string) => {
    getUserIuguID(userId);
  };
  useEffect(() => {
    if (uid) {
      getUserIuguID(uid);
      // setUserIuguID(formValues.userId.iugu_id);
    }
  }, [formValues]);

  // get plans
  const getPlans = async () => {
    const auth = localStorage.getItem("auth");
    const authJson = auth ? JSON.parse(auth) : auth;
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/iugu/plans?s=0&l=1000`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authJson.access_token,
        },
      }
    );
    const data = await response.json();
    setPlans(data.items);
    return data;
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleChangePlan = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setFormValues({ ...formValues, plan_identifier: value });
  };
  const handleChange = (event: SelectChangeEvent<typeof payableOption>) => {
    const {
      target: { value },
    } = event;
    setPayableOption(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFormValues({ ...formValues, payable_with: value });
  };

  function getStyles(name: string, payableOption: string[]) {
    return {
      fontWeight: payableOption.indexOf(name) === -1 ? "regular" : "bold",
    };
  }

  const formValidation = (values: any) => {
    const errors: any = [];
    // customer_id
    if (!values.customer_id) {
      errors.customer_id = ["Cliente Obrigatório"];
    }
    // plan_identifier
    if (!values.plan_identifier) {
      errors.plan_identifier = ["Plano obrigatório"];
    }
    if (!values.payable_with || values.payable_with.length === 0) {
      errors.payable_with = ["Obrigatório"];
    }
    // if (!values.expires_at) {
    //   errors.expires_at = ["Obrigatório"];
    // }
    return errors;
  };

  const handleSubmit = async () => {
    if (formValidation(formValues).length > 0) {
      notify("Preencha todos os campos obrigatórios", { type: "error" });
      return;
    }

    const formData = new FormData();
    // from formValues
    const { plan_identifier, payment_methods, expires_at } = formValues;
    formData.append("payable_with", payment_methods);
    // formData.append("expires_at", expires_at);
    formData.append("customer_id", userIuguID);
    formData.append("plan_identifier", plan_identifier);

    try {
      const auth = localStorage.getItem("auth");
      const authJson = auth ? JSON.parse(auth) : auth;
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/iugu/subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authJson.access_token,
          },
          body: JSON.stringify(formValues),
        }
      );
      const data = await response.json();
      if (data.hasOwnProperty("success") && !data.success) {
        notify("Erro ao criar fatura. " + (data.message ? data.message : ""), {
          type: "error",
        });
        return;
      }
      // if (data.hasOwnProperty("success") && data.success)
      notify("Fatura criada com sucesso", { type: "success" });
      // redirect to list after 3 seconds
      setTimeout(() => {
        window.location.href = "/#/iugu-invoices";
      }, 3000);
    } catch (error) {
      console.error("IUGU Invoice error", error);
      notify("Erro ao criar fatura", { type: "error" });
    }
  };

  return (
    <Form {...props} onSubmit={handleSubmit}>
      <PageTopBreadcrumb
        title="Assinaturas IUGU"
        firstPath="/#/subscriptions"
        secondTitle="Nova"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardHeader title="Criar" />
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <ReferenceInput
                source="userId"
                reference="users/crud"
                perPage={10}
                fullWidth
                enableGetChoices={({ name }) => name && name.length >= 2}
              >
                <AutocompleteInput
                  label="Nome/E-mail/CPF"
                  optionText={optionRenderer}
                  optionValue="id"
                  defaultValue={uid}
                  fullWidth
                  filterToQuery={filterToQuery}
                  onChange={(e) => {
                    // setFormValues({ ...formValues, userId: e });
                    handleSetUserIuguID(e);
                  }}
                />
              </ReferenceInput>
              {userIuguID ? (
                <Alert severity="info">
                  Criando <b>assinatura</b> para cliente IUGU de ID:{" "}
                  {userIuguID}
                </Alert>
              ) : (
                <Alert severity="warning">
                  Usuário não está vinculado a um cliente IUGU
                </Alert>
              )}
              <TextField
                disabled={!userIuguID}
                select
                label="Métodos de Pagamento"
                fullWidth
                required
                helperText="Selecione um ou mais meios de pagamento para esta cobrança."
                SelectProps={{
                  multiple: true,
                  value: payableOption,
                  onChange(event: any) {
                    // console.log("event", event);
                    // console.log("child", child);
                    handleChange(event);
                  },
                  // onChange: handleChange,
                }}
              >
                {payableOptions.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, payableOption)}
                  >
                    {name === "all"
                      ? "Todos"
                      : name === "pix"
                      ? "PIX"
                      : name === "credit_card"
                      ? "Cartão de Crédito"
                      : "Boleto"}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                disabled={!userIuguID}
                select
                label="Plano de Assinatura"
                fullWidth
                required
                helperText="Selecione um plano de assinatura."
                SelectProps={{
                  value: formValues.plan_identifier || "",
                  onChange(event: any) {
                    // console.log("event", event);
                    // console.log("child", child);
                    handleChangePlan(event);
                  },
                  // onChange: handleChange,
                }}
              >
                {plans &&
                  plans.length &&
                  plans.map((plan: any) => (
                    <MenuItem key={plan.id} value={plan.identifier}>
                      <b>
                        {plan.name} [{plan.identifier}]
                      </b>
                      {plan.prices.map((price: any) => {
                        return (
                          <>
                            {" - "}
                            R${" "}
                            {(parseInt(price.value_cents) / 100)
                              .toFixed(2)
                              .replace(".", ",")}
                            {" - "}
                          </>
                        );
                      })}
                      Intervalo: {plan.interval + " " + plan.interval_type}
                    </MenuItem>
                  ))}
              </TextField>
              {/* <DateTimeInput
                source="expires_at"
                label="Expira em (Dia da primeira cobrança)"
                fullWidth
                required
                disabled={!userIuguID}
                helperText="Data de vencimento da primeira cobrança, vencimentos consecutivos irão seguir intervalo da assinatura."
                onChange={(e) => {
                  // format date from "2024-04-25T15:00" to "YYYY-MM-DD HH:MM:SS +TIMEZONE", force brazilian timezone
                  const date = new Date(e.target.value);
                  const formattedDate = date
                    .toISOString()
                    .replace("T", " ")
                    .replace("Z", " -03:00");

                  console.log({
                    formattedDate,
                    date,
                    original: e.target.value,
                  });
                  setFormValues({ ...formValues, expires_at: formattedDate });
                }}
              /> */}
              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={!userIuguID}
              >
                Criar Assinatura
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default CreateSubscriptionPage;
