import {
  CreateContextProvider,
  SimpleForm,
  TextInput,
  useCreateController,
  Toolbar,
  AutocompleteInput,
  useNotify,
  useRedirect,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { PageTopBreadcrumb } from "../../components/PageTopBreadcrumb";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Email from "@mui/icons-material/Email";
import { determineUserReadableRole } from "../users/UserUtils";

export const FeedbacksCreate = (props: any) => {
  const roles = localStorage.getItem("roles");
  const auth = localStorage.getItem("auth");
  const authJson = auth ? JSON.parse(auth) : auth;
  const id = authJson.id;

  const controller: any = useCreateController({
    resource: "feedbacks/crud",
  });

  const PostSaveButton = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = (data: any) => {
      notify(`Agradecemos seu contato. Responderemos assim que possível.`);
      if (
        determineUserReadableRole(roles) === "admin" ||
        determineUserReadableRole(roles) === "finance" ||
        determineUserReadableRole(roles) === "secretary" ||
        determineUserReadableRole(roles) === "comms"
      ) {
        redirect("/feedbacks/crud");
      } else if (determineUserReadableRole(roles) === "user") {
        redirect("/users/crud/" + id + "/show");
      } else {
        redirect("/");
      }
    };
    return (
      <SaveButton
        type="button"
        label="Enviar"
        icon={<Email />}
        mutationOptions={{ onSuccess }}
      />
    );
  };

  const FeedbackToolbar = () => (
    <Toolbar>
      <PostSaveButton />
    </Toolbar>
  );

  return (
    <>
      <CreateContextProvider value={controller}>
        <SimpleForm toolbar={<FeedbackToolbar />}>
          <PageTopBreadcrumb
            title="Contatos"
            // firstPath="/#/feedbacks/crud"
            secondTitle="Novo"
          />
          <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
            <CardHeader title="Criar" />
            <CardContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12} lg={12}>
                  <TextInput
                    source="email"
                    defaultValue={localStorage.getItem("identifier")}
                    className="fb-input"
                    label="Membro"
                    fullWidth
                    disabled
                  />
                  <TextInput
                    source="message"
                    className="fb-input"
                    label="Mensagem"
                    fullWidth
                    multiline
                  />
                  <AutocompleteInput
                    source="type"
                    label="Tipo de contato"
                    fullWidth
                    choices={[
                      { id: "other", name: "Outro" },
                      { id: "bug", name: "Problema (bug)" },
                      { id: "feature", name: "Recurso" },
                      { id: "contact", name: "Contato" },
                      { id: "debit", name: "Débito" },
                      { id: "request", name: "Solicitação de Alteração" },
                    ]}
                    className="fb-input"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleForm>
      </CreateContextProvider>
    </>
  );
};
