import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { determineUserReadableRole } from "../ra-components/users/UserUtils";
import ImportButton from "../ra-components/users/CustomImport";

interface BreadcrumbProps {
  title: string;
  secondTitle?: string;
  firstPath?: string;
  secondPath?: string;
  thirdTitle?: string;
  actionType?: "create" | "show" | "edit";
  importButton?: boolean;
  defaultModel?: string;
}

export const PageTopBreadcrumb = (props: BreadcrumbProps) => {
  const userRole = localStorage.getItem("roles");

  const { title, firstPath, secondTitle, secondPath, thirdTitle, actionType } =
    props;
  const { id } = useParams();
  return (
    <Grid
      container
      // direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ paddingTop: 3, paddingBottom: 2 }}
        >
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          {title && (
            <div>
              {firstPath ? (
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href={firstPath ? firstPath : "#"}
                >
                  <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {title}
                </Link>
              ) : (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {title}
                </Typography>
              )}
            </div>
          )}
          {secondTitle && (
            <div>
              {secondPath ? (
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href={secondPath ? secondPath : "#"}
                >
                  <ArrowRightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {secondTitle}
                </Link>
              ) : (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <ArrowRightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {secondTitle}
                </Typography>
              )}
            </div>
          )}
          {thirdTitle && (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <ArrowRightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {thirdTitle}
            </Typography>
          )}
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          size="small"
        >
          {props.importButton && (
            <ImportButton defaultModel={props.defaultModel} />
          )}
          {determineUserReadableRole(userRole) === "admin" &&
            actionType === "create" &&
            firstPath && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                href={firstPath + "/create"}
              >
                Novo
              </Button>
            )}
          {actionType === "show" && firstPath && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<FaEye />}
              href={firstPath + "/" + id + "/show"}
            >
              Ver
            </Button>
          )}
          {userRole &&
            (determineUserReadableRole(userRole) === "admin" ||
              determineUserReadableRole(userRole) === "user") &&
            actionType === "edit" &&
            firstPath && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaPencilAlt />}
                href={firstPath + "/" + id + "/edit"}
              >
                Alterar
              </Button>
            )}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};
