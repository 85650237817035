import { FC, useEffect, useState } from "react";
import Chart from "react-apexcharts";
interface PieChartProps {
  props: any;
}

const PieChart: FC<PieChartProps> = ({ props }) => {
  const [activeUsersCount, setActiveUsersResponse] = useState(0);
  const [deceasedUsersCount, setDeceasedUsersResponse] = useState(0);
  const [canceledUsersCount, setCanceledUsersResponse] = useState(0);
  const [licensedUsersCount, setLicensedUsersResponse] = useState(0);
  const [noContactUsersCount, setNoContactResponse] = useState(0);
  const [pendingUsersCount, setPendingUsersResponse] = useState(0);
  const [unknownUsersCount, setUnknownUsersResponse] = useState(0);
  const [revenueCount, setRevenueCount] = useState(0);
  const [expenseCount, setExpenseCount] = useState(0);
  const [subscriptionCount, setSubscriptionCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activeUsersResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "ACTIVE" }, // Filtro para retornar apenas o primeiro registro
          }
        );
        const totalActiveUsers = activeUsersResponse.total;
        setActiveUsersResponse(totalActiveUsers);
        const canceledUsersResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "CANCELED" },
          }
        );
        const totalCanceledUsers = canceledUsersResponse.total;
        setCanceledUsersResponse(totalCanceledUsers);

        const licensedUsersResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "LICENSED" },
          }
        );
        const totalLicensedUsers = licensedUsersResponse.total;
        setLicensedUsersResponse(totalLicensedUsers);

        const pendingUsersResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "PENDING" },
          }
        );
        const totalPendingUsers = pendingUsersResponse.total;
        setPendingUsersResponse(totalPendingUsers);

        const deceasedUsersResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "DECEASED" },
          }
        );
        const totalDeceasedUsers = deceasedUsersResponse.total;
        setDeceasedUsersResponse(totalDeceasedUsers);

        const noContactUserResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "NO_CONTACT" },
          }
        );
        const totalNoContact = noContactUserResponse.total;
        // console.log({ totalNoContact });
        setNoContactResponse(totalNoContact);

        const unknownUserResponse = await props.dataProvider.getList(
          "users/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { accountStatus: "UNKNOWN" },
          }
        );
        const totalUnknown = unknownUserResponse.total;
        setUnknownUsersResponse(totalUnknown);

        const revenueResponse = await props.dataProvider.getList(
          "transactions/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { type: "INCOME" }, // Filtro para receitas
          }
        );
        const totalRevenue = revenueResponse.total;
        setRevenueCount(totalRevenue);

        const expenseResponse = await props.dataProvider.getList(
          "transactions/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: { type: "EXPENSE" }, // Filtro para despesas
          }
        );
        const totalExpense = expenseResponse.total;
        setExpenseCount(totalExpense);

        const subscriptionResponse = await props.dataProvider.getList(
          "subscriptions/crud",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
            filter: false,
          }
        );
        const totalSubscriptions = subscriptionResponse.total;
        setSubscriptionCount(totalSubscriptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props]);

  const options = {
    labels: [
      "Ativos: " + activeUsersCount,
      "Cancelados: " + canceledUsersCount,
      "Falecidos: " + deceasedUsersCount,
      "Sem Contato: " + noContactUsersCount,
      "Licenciados: " + licensedUsersCount,
      "Pendentes: " + pendingUsersCount,
      "Não Informado: " + unknownUsersCount,
    ],
    colors: [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#33CC33",
      "#FF9900",
      "#CCCCCC",
      "#000000",
    ],
    chart: {
      width: "100%",
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
        },
      },
    },
  };
  const series = [
    activeUsersCount,
    canceledUsersCount,
    deceasedUsersCount,
    noContactUsersCount,
    licensedUsersCount,
    unknownUsersCount,
  ];
  const cardData = [
    {
      title: "Receitas",
      content: `Total: ${revenueCount}`,
    },
    {
      title: "Despesas",
      content: `Total: ${expenseCount}`,
    },
    {
      title: "Assinaturas",
      content: `Total: ${subscriptionCount}`,
    },
    {
      title: "Sócios cadastrados",
      content: `Total: ${
        activeUsersCount +
        canceledUsersCount +
        deceasedUsersCount +
        noContactUsersCount +
        licensedUsersCount +
        unknownUsersCount
      }`,
    },
  ];

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flex: "1",
          marginRight: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "1rem",
          }}
        >
          {cardData.map((card, index) => (
            <div
              key={index}
              style={{
                background: "white",
                padding: "1rem",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <p>{card.title}</p>
              <h3
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                {card.content}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <div style={{ flex: "1", marginLeft: "1rem" }}>
        <div
          style={{
            background: "white",
            padding: "1rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Chart options={options} series={series} type="donut" />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
