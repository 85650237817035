import React, { useEffect, useState } from "react";
import {
  Form,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { PageTopBreadcrumb } from "../../../components/PageTopBreadcrumb";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import StorageIcon from "@mui/icons-material/Storage";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type IuguItems = {
  [key: string]: any;
  quantity: number;
  price_cents: number;
  description: string;
};

type PayableWithIugu = "pix" | "credit_card" | "bank_slip";

const CreateInvoiceForm = (props: any) => {
  const queryUrl = window.location.href;
  // extract uid from url
  let uid = queryUrl.split("/").pop();
  uid = uid ? uid.split("=").pop() : "";

  // let preSelectUser = urlParams["*"];
  if (uid === "create") {
    uid = "";
  }

  const notify = useNotify();
  const payableOptions = ["pix", "credit_card", "bank_slip"];
  const [formValues, setFormValues] = useState({} as any);
  const [userIuguID, setUserIuguID] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [items, setItems] = useState<IuguItems[]>([]);
  const dataProvider = useDataProvider();
  const [payableOption, setPayableOption] = React.useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filterToQuery = (searchText: any) => ({
    mailName: `${searchText}`,
  });
  const optionRenderer = (choice: any) =>
    `${choice.fullName} ${choice.cpf ? "- CPF: " + choice.cpf : ""} 
    ${
      choice.registrationNumber
        ? " - Matrícula: " + choice.registrationNumber
        : ""
    }${
      choice.registrationNumberDigit ? "-" + choice.registrationNumberDigit : ""
    }`;

  const handleItemChange = (index: number, field: string, value: any) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([...items, { price_cents: 0, quantity: 0, description: "" }]);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };
  useEffect(() => {
    let total = 0;
    items.forEach((item) => {
      if (item.price_cents && item.quantity) {
        total += item.price_cents * item.quantity;
      }
    });
    setTotalPrice(total);
    // transform item prices to cents
    const newItems = items.map((item: any) => ({
      ...item,
      price_cents: item.price_cents * 100,
    }));
    setFormValues({ ...formValues, items: newItems });
  }, [items]);
  const getUserIuguID = async (uid: string) => {
    // console.log("getUserIuguID", uid);
    try {
      const { data } = await dataProvider.getOne("users/crud", {
        id: uid,
      });
      const userEmail = data.email;
      const userCPF = data.cpf.replace(/[.-]/g, "");
      const payer = {
        cpf_cnpj: userCPF,
        name: data.fullName,
        email: data.email,
      };
      const customer_id = data.iuguId;
      setUserIuguID(data.iuguId);
      // if no email
      if ((!formValues.email && userEmail) || formValues.email !== userEmail) {
        setFormValues({
          ...formValues,
          email: userEmail,
          payer,
          customer_id,
        });
      }
      // setFormValues({ ...formValues, email: userEmail });
    } catch (error) {
      console.error("getUserIuguID error", error);
    }
    // setFormValues({ ...formValues, userId: data });
  };
  const handleSetUserIuguID = async (userId: string) => {
    getUserIuguID(userId);
  };
  useEffect(() => {
    if (uid) {
      getUserIuguID(uid);
      // setUserIuguID(formValues.userId.iugu_id);
    }
  }, [formValues]);

  const handleChange = (event: SelectChangeEvent<typeof payableOption>) => {
    const {
      target: { value },
    } = event;
    setPayableOption(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFormValues({ ...formValues, payable_with: value });
  };

  function getStyles(name: string, payableOption: string[]) {
    return {
      fontWeight: payableOption.indexOf(name) === -1 ? "regular" : "bold",
    };
  }

  const formValidation = (values: any) => {
    const errors: any = [];

    if (!values.items || values.items.length === 0) {
      errors.items = ["Obrigatório"];
    }
    if (!values.payable_with || values.payable_with.length === 0) {
      errors.payable_with = ["Obrigatório"];
    }
    if (!values.due_date) {
      errors.due_date = ["Obrigatório"];
    }
    return errors;
  };

  const handleSubmit = async () => {
    if (formValidation(formValues).length > 0) {
      notify("Preencha todos os campos obrigatórios", { type: "error" });
      return;
    }

    const formData = new FormData();
    // from formValues
    const { email, items, payment_methods, due_date } = formValues;
    formData.append("items", JSON.stringify(items));
    formData.append("payable_with", payment_methods);
    formData.append("due_date", due_date);
    formData.append("customer_id", userIuguID);
    formData.append("email", email);

    try {
      const auth = localStorage.getItem("auth");
      const authJson = auth ? JSON.parse(auth) : auth;
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/iugu/invoices/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authJson.access_token,
          },
          body: JSON.stringify(formValues),
        }
      );
      const data = await response.json();
      if (data.hasOwnProperty("success") && !data.success) {
        notify("Erro ao criar fatura. " + (data.message ? data.message : ""), {
          type: "error",
        });
        return;
      }
      // if (data.hasOwnProperty("success") && data.success)
      notify("Fatura criada com sucesso", { type: "success" });
      // redirect to list after 3 seconds
      setTimeout(() => {
        window.location.href = "/#/iugu-invoices";
      }, 3000);
    } catch (error) {
      console.error("IUGU Invoice error", error);
      notify("Erro ao criar fatura", { type: "error" });
    }
  };

  return (
    <Form {...props} onSubmit={handleSubmit}>
      <PageTopBreadcrumb
        title="Faturas IUGU"
        firstPath="/#/iugu-invoices"
        secondTitle="Nova"
      />
      <Card sx={{ padding: 2, marginBottom: 3, width: "100%" }}>
        <CardHeader title="Criar" />
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <ReferenceInput
                source="userId"
                reference="users/crud"
                perPage={10}
                fullWidth
                enableGetChoices={({ name }) => name && name.length >= 2}
              >
                <AutocompleteInput
                  label="Nome/E-mail/CPF"
                  optionText={optionRenderer}
                  optionValue="id"
                  defaultValue={uid}
                  fullWidth
                  filterToQuery={filterToQuery}
                  onChange={(e) => {
                    // setFormValues({ ...formValues, userId: e });
                    handleSetUserIuguID(e);
                  }}
                />
              </ReferenceInput>
              {userIuguID ? (
                <Alert severity="info">
                  Criando cobrança para cliente IUGU de ID: {userIuguID}
                </Alert>
              ) : (
                <Alert severity="warning">
                  Usuário não está vinculado a um cliente IUGU
                </Alert>
              )}
              <Paper
                elevation={3}
                style={{
                  padding: 10,
                  backgroundColor: "#fcfcfc",
                  borderColor: "#e0e0e0",
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                <Grid container spacing={2}>
                  {items.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            label="Preço"
                            type="number"
                            value={item.price_cents}
                            required
                            disabled={!userIuguID}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "price_cents",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Quantidade"
                            type="number"
                            required
                            value={item.quantity}
                            disabled={!userIuguID}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Descrição"
                            required
                            value={item.description}
                            disabled={!userIuguID}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </Grid>

                        <Grid item xs={1} mt={2}>
                          <Button
                            onClick={() => handleRemoveItem(index)}
                            startIcon={<DeleteIcon />}
                            variant="outlined"
                            disabled={!userIuguID}
                            fullWidth
                          ></Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      onClick={handleAddItem}
                      startIcon={<AddIcon />}
                      size="small"
                      variant="outlined"
                      title="Adicionar item"
                      disabled={!userIuguID}
                    >
                      Adicionar item
                    </Button>
                    <Button
                      onClick={handleOpen}
                      title="Adicionar da base de produtos"
                      variant="outlined"
                      disabled={!userIuguID}
                      size="small"
                      startIcon={<StorageIcon />}
                    >
                      Adicionar dos Itens de Cobrança
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          position: "absolute" as "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          border: "2px solid #000",
                          boxShadow: 24,
                          p: 4,
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Adicionar dos Itens de Cobrança
                        </Typography>
                        <Box id="modal-modal-description" sx={{ mt: 2 }}>
                          <Typography variant="caption" gutterBottom>
                            Selecione um produto existente nos Itens de Cobrança
                            para adicionar ao item da linha.
                          </Typography>
                          <ReferenceInput
                            source="productId"
                            reference="products/crud"
                            perPage={10}
                            enableGetChoices={({ q }) => q && q.length >= 2}
                            filter={{ is_published: true }}
                          >
                            <AutocompleteInput
                              label="Produto"
                              optionText="name"
                              optionValue="id"
                              // value={item.description}
                              disabled={!userIuguID}
                              onChange={async (e) => {
                                // setFormValues({ ...formValues, productId: e });
                                try {
                                  const productData = await dataProvider.getOne(
                                    "products/crud",
                                    {
                                      id: e,
                                    }
                                  );
                                  const index = items.length;
                                  const newItems = [...items];
                                  newItems[index] = {
                                    ...newItems[index],
                                    description: productData.data.name,
                                    price_cents: productData.data.price,
                                    quantity: 1,
                                  };
                                  setItems(newItems);
                                } catch (error) {
                                  console.log("AutoComplete error", {
                                    e,
                                    error,
                                  });
                                }
                              }}
                            />
                          </ReferenceInput>
                        </Box>
                      </Box>
                    </Modal>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Preço Total: R$ {totalPrice.toFixed(2).replace(".", ",")}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <TextField
                disabled={!userIuguID}
                select
                label="Métodos de Pagamento"
                fullWidth
                required
                helperText="Selecione um ou mais meios de pagamento para esta cobrança."
                SelectProps={{
                  multiple: true,
                  value: payableOption,
                  onChange(event: any) {
                    // console.log("event", event);
                    // console.log("child", child);
                    handleChange(event);
                  },
                  // onChange: handleChange,
                }}
              >
                {payableOptions.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, payableOption)}
                  >
                    {name === "all"
                      ? "Todos"
                      : name === "pix"
                      ? "PIX"
                      : name === "credit_card"
                      ? "Cartão de Crédito"
                      : "Boleto"}
                  </MenuItem>
                ))}
              </TextField>
              <DateInput
                source="due_date"
                label="Data de Vencimento"
                fullWidth
                required
                disabled={!userIuguID}
                onChange={(e) => {
                  setFormValues({ ...formValues, due_date: e.target.value });
                }}
              />

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={!userIuguID}
              >
                Criar Fatura
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default CreateInvoiceForm;
